import React, { useEffect, useState } from "react";
import { useParams, Link,useNavigate } from "react-router-dom";
import { ValidationForm, TextInput } from "react-bootstrap4-form-validation";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';


const ProfilePage = () => {

    const [tools,setTools] = useState([]);
    const [intro,setIntro] = useState([]);
    const [editProfile,setEditProfile] = useState({});

    const navigate = useNavigate()


    // const [data,setData] = useState({
    //     username:"",
    //     password:"",
    //     email:"",
    // });
    var token = localStorage.getItem("safety_alliance_token");

    var spoofUser = localStorage.getItem("spoof_user");

    if(spoofUser){
      var userId = spoofUser;
    }else{
      var userId = localStorage.getItem("safety_alliance_userId");
    }

    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        // 'Content-Type': 'application/x-www-form-urlencoded'
        
      }

      const headers2 = {
        'accept': '*/*',
        'Authorization': `Bearer ${token}`,
        
      }
      const handleChange = (e) => {
        const value = e.target.value;
        setEditProfile({
          ...editProfile,
          [e.target.name]: value
        });
      };

    const [isReport, setIsReport] = useState(true);
    const [userDataS, setUserDataS] = useState("");
    const [hasReport2, setHasReport2] = useState(false);
    const [dataReport, setDataReport] = useState([]);


    
    const [showRegister,setShowRegister] = useState(true);
    const [isLogin,setIsLogin] = useState(false);
    // var userId = localStorage.getItem("safety_alliance_userId");
    var{areaSlug}= useParams();
    const{postSlug} = useParams();

    const fetchData=async()=>{
        axios.get('https://ohs.msabc.online/api/v1/tools').then
        ((res) =>{
            const allTools = res.data; 
            setTools(allTools);
            console.log(allTools);
            fetchUser();
    
        })
    }
    const fetchUser = () =>{
        axios.get(`https://ohs.msabc.online/api/v1/users/${userId}`,
        {headers}).then
        ((res) =>{
            console.log(res.data);
            var tempUser = res.data[0];
            setUserDataS(tempUser);
            setEditProfile(tempUser);
            var temp = res.data[0].activity.status;
            console.log(temp);
            setDataReport(temp);

    
        })
    }
    const onSubmitForm=async(e) =>{
        e.preventDefault();
        const userData = JSON.stringify({    
            user_username:editProfile.email,
            user_password: editProfile.password,
            email: editProfile.email,
            firstname:editProfile.firstname,   
            lastname:editProfile.lastname,  
            organization: editProfile.organization,
            role: editProfile.role,
            sector: editProfile.sector,
            phone : editProfile.phone,
        
        });
        console.log(userData);
        console.log(headers);
        axios.patch(`https://ohs.msabc.online/api/v1/users/${userId}`,userData,{
            headers:headers
        })
        .then((res) => {
            console.log(res);
 
            console.log(res.message);
            toast.success('🦄 Profile Successfuly Updated!', {
              position: "top-center",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              });
              setEditProfile(true);
            // window.location.reload();

        })
        .catch(error =>{
            console.log("Error is -----",error);
        })


    }

    const logOut =() =>{
        localStorage.clear();
        setIsLogin(false);
        navigate('/');

        window.location.reload();
    }
    const getReport=(tId)=>{
      axios.post(`https://ohs.msabc.online/api/v1/reports/{reportType}/tools/${tId}/users/${userId}/download`,"",{headers}).then
      ((res) =>{
      console.log(res.data);
  
  
      })
    }

    // useEffect(()=>{
    //     // document.body.classList.add('green-pattern');
    // })
    React.useEffect(()=>{
        fetchData();
    },[])


    const toolList = tools.map((data,id)=>{
        return <div class="p-7 md:pb-16 rounded-3xl shadow-xxl bg-white">
        <div class="flex justify-between items-center mb-9"> <img src={data.icon} width="41" height="48"
            alt=""/>  </div>
        <p class="block font-ProximaNovaRg text-base font-bold mb-3 text-lightblue tracking-wider uppercase">
          {data.subtitle}
        </p>
        <h3 class="text-3xl font-bold mb-2"> {data.title}</h3>
        <p class="text-base mb-4">{data.description}</p>
        <p class="text-center mt-4 md:mt-9">
            { dataReport[id+1]?.attemptStarted >= 1 ?<> 
                {/* <a href={`/tools/${id+1}/report/0`} target="_blank" class="btn-primary" >Get Report</a> */}
                <a onClick={()=>{getReport(id+1)}} class="btn-primary">View Report</a> 

   
            </> : 
            <>
         <a href="#" data-bs-toggle="modal"
            data-bs-target="#Report-pending-Popup" class="btn-primary">View Report</a>
            </>
            } </p>
      </div>



    })





    return (
        < >
        <section class="common-content md:pt-12 md:pb-16 pt-8 pb-8 bg-white overflow-hidden bg-extralightblue">
      <div class="container max-w-7xl ml-auto mr-auto">

        <div class="flex flex-wrap w-full">
          <div class="green-gradient w-full lg:w-1/4 rounded-lg md:px-11 px-5 py-5">

            <h3 class="block font-ProximaNovaRg font-bold text-white uppercase mb-1 w-full mt-8">PROFILE</h3>

            <div class="flex items-center mb-10">
              <div class="w-14 rounded-full overflow-hidden mr-3"> <img src="img/user-placeholder.png" alt=""/> </div>
              <div class="block">
                <p class="font-ProximaNovaRg font-bold text-white m-0 leading-tight">{userDataS?.firstname}</p>
              </div>
            </div>

            <h3 class="block font-ProximaNovaRg font-bold text-white uppercase mb-1">MENU</h3>

            <ul class="block w-full">

              <li class="text-white border-b border-gray-100  block py-1"><a onClick={()=>{setIsReport(false)}}>Profile Settings</a></li>
              <li class="text-white border-b border-gray-100 block py-1"><a onClick={()=>{setIsReport(true)}}>Reports</a></li>
            </ul>



            <p class="text-center w-full mt-12">
              <a onClick={logOut}
                class="btn-primary bg-[rgba(255,255,255,0.3)] text-white hover:bg-white hover:text-blue font-ProximaNovaRg">Log
                Out</a>
            </p>

          </div>

          { isReport? 
          
          <>
          <div class="flex flex-wrap w-full lg:w-3/4 lg:pl-6 pt-6 lg:pt-0">

            <div class="flex-wrap grid md:grid-cols-2 gap-6 md:gap-10 mt-4 md:mt-0">
                {toolList}
            </div>
            <div
              class="modal fade fixed top-0 left-0  hidden w-full h-full outline-none overflow-x-hidden overflow-y-auto"
              id="Report-pending-Popup" tabindex="-1" aria-labelledby="Report-pending-Popup" aria-hidden="true">
              <div class="modal-dialog relative w-auto pointer-events-none">
                <div
                  class="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-xl outline-none text-current">
                  <div
                    class="modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md">
                    <h5 class="text-xl font-bold leading-normal text-blue">
                      Report Status
                    </h5>
                    <button type="button"
                      class="btn-close absolute right-5 top-5 font-bold box-content w-4 h-4 p-0 border border-black text-black rounded-full opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline leading-4"
                      data-bs-dismiss="modal" aria-label="Close">X</button>
                  </div>
                  <div class="modal-body relative p-4 py-6">
                    <p class="text-2xl mb-5">To view report finish tool questionnaire </p>
                    <a href="#" class="btn-primary">Go Back</a>
                  </div>
                </div>
              </div>
            </div> 
           


          </div>
          </>:
          <>
            <div class="flex flex-wrap w-full lg:w-3/4 lg:pl-6 pt-6 lg:pt-0">

            <div class="flex flex-wrap w-full">
            <div class="w-full bg-white rounded-lg rounded-t-none overflow-hidden">
      
      
             <div class="px-8 pb-8 lg:px-24 lg:pb-20">
             
             <div class="flex items-center mb-6">
              <div class="block mt-5">
                <p class="font-ProximaNovaRg font-bold text-black m-0 leading-tight text-xl">{editProfile.firstname}</p>        
              </div>
            </div>
      
      
            <ValidationForm onSubmit={onSubmitForm} >
            <div class="flex flex-wrap w-full">
           <h2 class="text-xl font-semibold w-full">Personal Info</h2>
      
           <p></p>
      
      
           <div class="flex flex-wrap clear-both w-full pt-4">
            <div class="w-full md:w-1/2">
            <div class="mt-4 md:mr-6">
              <label class="form-label inline-block mb-2 text-lg">Username</label>
              <TextInput type="text" name="username" id="username"  onChange={handleChange} placeholder="Enter your full name" defaultValue={editProfile.email}
              class="form-control block w-full px-3.5 py-2 text-base font-normal text-black bg-white border border-solid border-gray-300 rounded-xl transition ease-in-out m-0 focus:border-blue focus:outline-none"/>
            </div>
          </div>


          </div>
          <div class="flex flex-wrap clear-both w-full pt-4">
            <div class="w-full md:w-1/2">
            <div class="mt-4 md:mr-6">
              <label class="form-label inline-block mb-2 text-lg">First Name</label>
              <TextInput name="firstname" id="firstname" defaultValue={editProfile.firstname} onChange={handleChange} type="text" placeholder="First Name" 
              class="form-control block w-full px-3.5 py-2 text-base font-normal text-black bg-white border border-solid border-gray-300 rounded-xl transition ease-in-out m-0 focus:border-blue focus:outline-none"/>
            </div>
          </div>
          <div class="w-full md:w-1/2">
            <div class="mt-4 md:ml-6">
              <label class="form-label inline-block mb-2 text-lg">Last Name</label>
              <TextInput 
              name="lastname" id="lastname" value={editProfile.lastname} onChange={handleChange} type="text" placeholder="Last Name"
              class="form-control block w-full px-3.5 py-2 text-base font-normal text-black bg-white border border-solid border-gray-300 rounded-xl transition ease-in-out m-0 focus:border-blue focus:outline-none"/>
            </div>
          </div>
          </div>
          <div class="flex flex-wrap clear-both w-full pt-4">
            <div class="w-full md:w-1/2">
            <div class="mt-4 md:mr-6">
              <label class="form-label inline-block mb-2 text-lg">Organization</label>
              <TextInput name="organization" id="organization" defaultValue={editProfile.organization} onChange={handleChange} type="text" placeholder="Organization" 
              class="form-control block w-full px-3.5 py-2 text-base font-normal text-black bg-white border border-solid border-gray-300 rounded-xl transition ease-in-out m-0 focus:border-blue focus:outline-none"/>
            </div>
          </div>
          <div class="w-full md:w-1/2">
            <div class="mt-4 md:ml-6">
              <label class="form-label inline-block mb-2 text-lg">Role</label>
              <TextInput 
              name="role" id="role" value={editProfile.role} onChange={handleChange} type="text" placeholder="Role"
              class="form-control block w-full px-3.5 py-2 text-base font-normal text-black bg-white border border-solid border-gray-300 rounded-xl transition ease-in-out m-0 focus:border-blue focus:outline-none"/>
            </div>
          </div>
          </div>
          <div class="flex flex-wrap clear-both w-full pt-4">
            <div class="w-full md:w-1/2">
            <div class="mt-4 md:mr-6">
              <label class="form-label inline-block mb-2 text-lg">Sector</label>
              <TextInput name="sector" id="sector" defaultValue={editProfile.sector} onChange={handleChange} type="text" placeholder="Sector" 
              class="form-control block w-full px-3.5 py-2 text-base font-normal text-black bg-white border border-solid border-gray-300 rounded-xl transition ease-in-out m-0 focus:border-blue focus:outline-none"/>
            </div>
          </div>
          <div class="w-full md:w-1/2">
            <div class="mt-4 md:ml-6">
              <label class="form-label inline-block mb-2 text-lg">Phone</label>
              <TextInput 
              name="phone" id="phone" value={editProfile.phone} onChange={handleChange} type="text" placeholder="Phone"
              class="form-control block w-full px-3.5 py-2 text-base font-normal text-black bg-white border border-solid border-gray-300 rounded-xl transition ease-in-out m-0 focus:border-blue focus:outline-none"/>
            </div>
          </div>
          </div>
          
          
      
          <div class="flex flex-wrap clear-both w-full pt-4">
            <div class="w-full md:w-1/2">
            <div class="mt-4 md:mr-6">
              <label class="form-label inline-block mb-2 text-lg">Email</label>
              <TextInput name="email" id="email" defaultValue={editProfile.email} onChange={handleChange} type="email" placeholder="Enter your email" 
              class="form-control block w-full px-3.5 py-2 text-base font-normal text-black bg-white border border-solid border-gray-300 rounded-xl transition ease-in-out m-0 focus:border-blue focus:outline-none"/>
            </div>
          </div>
          <div class="w-full md:w-1/2">
            <div class="mt-4 md:ml-6">
              <label class="form-label inline-block mb-2 text-lg">Password</label>
              <TextInput 
              name="password" id="password" value={editProfile.password} onChange={handleChange} type="password" placeholder="Enter your password"
              class="form-control block w-full px-3.5 py-2 text-base font-normal text-black bg-white border border-solid border-gray-300 rounded-xl transition ease-in-out m-0 focus:border-blue focus:outline-none"/>
            </div>
          </div>
          </div>
      
          <div class="flex space-x-3 justify-end w-full mt-8"> <a href="#" class="btn-secondary pl-4 pr-4 border-gray-400 hover:bg-slate-600 hover:border-slate-600">Cancel</a> 
          <input type="submit" value="submit" class="btn-primary green-gradient font-medium hover:bg-black"/> </div>
      
      
      
            </div>
            </ValidationForm>
      
          </div>
      
      
              </div>
              </div>
              </div>

        </>

}
        </div>




      </div>

    </section>

    <ToastContainer
position="top-center"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="light"
/>

       </>
    );
  };
  const Profile = () => {
    return (
      <>
        <ProfilePage />

      </>
    );
  };
  
  export default Profile;