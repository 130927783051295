import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import 'survey-core/defaultV2.min.css';
import { StylesManager, Model } from 'survey-core';
import axios from 'axios';
import { Survey } from "survey-react-ui";
import $ from "jquery";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { out } from "@amcharts/amcharts5/.internal/core/util/Ease";
import parse from 'html-react-parser';




// import { formData } from "./formData";


// StylesManager.applyTheme("defaultV2");

const UnitDetails = () => {

    const navigate = useNavigate();
    const [units,setUnits] = useState([]);
    const [units1,setUnits1] = useState([]);
    const [units2,setUnits2] = useState([]);
    
    const [questionOb, setQuestionOb] = useState([]);
    const [allQuest,setAllQuest] = useState([]);
    const [question, setQuestion] = useState([]);
    const{postSlug} = useParams();
    var{areaSlug}= useParams();
    var {unitSlug}= useParams();
    const [color,setColor] = useState('');
    const [radioValue, setRadioValue] = useState("");
    const [page, setPage] = useState(0);
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(1);
    const [questionId,setQuestionId] = useState();
    const [unitId,setUnitId] = useState(0);
    const [areaLength, setAreaLength] = useState(0);
    const [isPatch,setIsPatch]=useState(false);
    const [selectedFile, setSelectedFile] = useState();
    const [isFilePicked, setIsFilePicked] = useState(false);
    const [loadValue, setLoadValue] = useState([]);
    const [existingValue,setExistingValue] = useState([]);
    const [progress, setProgress] = useState(0);
    const [area,setArea] = useState([]);
    const [arrFiles,setArrFiles] = useState([]);
    const [isFile,setIsFile] = useState(false);

    var tempArrFile = [];

    //Form Tool 2
    const defaultValues = {
      "2": "2",
      "3": "hhhishhd",
      "4": ["3","2"]
    }
    const { register, handleSubmit,reset, setValue } = useForm();
    const [data, setData] = useState("");
        
    var spoofUser = localStorage.getItem("spoof_user");

    if(spoofUser){
      var userId = spoofUser;
    }else{
      var userId = localStorage.getItem("safety_alliance_userId");
    }

    var token = localStorage.getItem("safety_alliance_token");
    // const [selected, setSelected] = useState("2");


 
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
      
    }
    const headersFile = {
      'accept': 'application/json',
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'multipart/form-data'
    }


  //Accordion
    const handleOpen = (value) => {
    setOpen(open === value ? 0 : value);
  };

	const changeHandler = (event) => {
    	setSelectedFile(event.target.files[0]);
    	setIsFilePicked(true);
    	};
  
    const handleChange = (e) => { 
      setRadioValue(e.target.value);
      console.log(e.target.value);
    
  };
  const handleSubmit2 = (dataTool2)=> {

    // const answer = JSON.stringify({
    //     "choice": {
    //       "choiceId": parseInt(radioValue),
    //       "responseText": "look at me example text"
    //     }
    //   })
  
    //   console.log(radioValue);
    //   console.log(answer);
    if(postSlug == 1){
        const answer = JSON.stringify({
            questionId:parseInt(radioValue)
          })
      
          console.log(radioValue);
          console.log(answer);

if(isPatch){
    axios.patch(`https://ohs.msabc.online/api/v1/responses/users/${userId}/tools/${postSlug}/areas/${areaSlug}/units/${unitId}/questions/${questionId}`,answer,{
      headers:headers
    }).then
    ((res)=>{
      console.log(res);
      console.log(parseInt(unitSlug));
      console.log(areaLength-1);

      if(unitSlug == (areaLength-1) && areaSlug != 8){
        unitSlug = 0;
        areaSlug = parseInt(areaSlug)+1;
      navigate("/tools/"+postSlug+"/area/"+areaSlug+"/Unit/"+(parseInt(unitSlug)));
      }
      else if(unitSlug == (areaLength-1) && areaSlug == (8)){
        navigate(`/tools/${postSlug}/summary/${postSlug > 1 ? `9`:`1`}`);
      }
      else{
        navigate("/tools/"+postSlug+"/area/"+areaSlug+"/Unit/"+(parseInt(unitSlug)+1));

      }
      window.location.reload();
    })
    .catch(error =>{
      console.log("Error is -----",error);
  })
}else{
  axios.post(`https://ohs.msabc.online/api/v1/responses/users/${userId}/tools/${postSlug}/areas/${areaSlug}/units/${unitId}/questions/${questionId}`,answer,{
    headers:headers
  }).then
  ((res)=>{


    console.log(res);
    console.log(parseInt(unitSlug));
    console.log(areaLength-1);

    if(unitSlug == (areaLength-1) && areaSlug != 8){
      unitSlug = 0;
      areaSlug = parseInt(areaSlug)+1;
    navigate("/tools/"+postSlug+"/area/"+areaSlug+"/Unit/"+(parseInt(unitSlug)));
    }
    else if(unitSlug == (areaLength-1) && areaSlug == "8"){
      navigate(`/tools/${postSlug}/summary/${postSlug > 1 ? `9`:`1`}`);
    }
    else{
      navigate("/tools/"+postSlug+"/area/"+areaSlug+"/Unit/"+(parseInt(unitSlug)+1));

    }
    window.location.reload();
  })
  .catch(error =>{
    console.log("Error is -----",error);


})

}
    }else{

        if(isPatch){
            axios.patch(`https://ohs.msabc.online/api/v1/responses/users/${userId}/tools/${postSlug}/areas/${areaSlug}/units/${unitId}`,dataTool2,{
              headers:headers
            }).then
            ((res)=>{
              console.log(res);
              console.log(parseInt(unitSlug));
              console.log(areaLength-1);
        
              if(unitSlug == (areaLength-1) && areaSlug != 13){
                unitSlug = 0;
                areaSlug = parseInt(areaSlug)+1;
              navigate("/tools/"+postSlug+"/area/"+areaSlug+"/Unit/0");
              }
              else if(unitSlug == (areaLength-1) && areaSlug == 13){
                navigate(`/tools/${postSlug}/summary/${postSlug > 1 ? `9`:`1`}`);
              }
              else{
                navigate("/tools/"+postSlug+"/area/"+areaSlug+"/Unit/"+(parseInt(unitSlug)+1));
        
              }
              window.location.reload();
            })
            .catch(error =>{
              console.log("Error is -----",error);
          })
        }else{
          axios.post(`https://ohs.msabc.online/api/v1/responses/users/${userId}/tools/${postSlug}/areas/${areaSlug}/units/${unitId}`,dataTool2,{
            headers:headers
          }).then
          ((res)=>{
        
        
            console.log(res);
            console.log(parseInt(unitSlug));
            console.log(areaLength-1);
        
            if(unitSlug == (areaLength-1) && areaSlug != 13){
              unitSlug = 0;
              areaSlug = parseInt(areaSlug)+1;
            navigate("/tools/"+postSlug+"/area/"+areaSlug+"/Unit/"+(parseInt(unitSlug)));
            }
            else if(unitSlug == (areaLength-1) && areaSlug == "13"){
              navigate(`/tools/${postSlug}/summary/${postSlug > 1 ? `9`:`1`}`);
            }
            else{
              navigate("/tools/"+postSlug+"/area/"+areaSlug+"/Unit/"+(parseInt(unitSlug)+1));
        
            }
            window.location.reload();
          })
          .catch(error =>{
            console.log("Error is -----",error);
        
        
        })
        
        }


        // if(unitSlug == (areaLength-1) && areaSlug != 8){
        //     unitSlug = 0;
        //     areaSlug = parseInt(areaSlug)+1;
        //   navigate("/tools/1/area/"+areaSlug+"/Unit/"+(parseInt(unitSlug)));
        //   }
        //   if(unitSlug == (areaLength-1) && areaSlug == (8)){
        //     navigate("/tools/"+postSlug+"/summary");
        //   }
        //   else{
        //     navigate("/tools/"+postSlug+"/area/"+areaSlug+"/Unit/"+(parseInt(unitSlug)+1));
    
        //   }
        //   window.location.reload();
        
    }
  };
  const handleNext =()=>{
    if(radioValue == existingValue){
      handleSkip();
    }else{
    handleSubmit2();
    }

    
  }

//   const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
//     console.log('change', event.target.value);
//   };

  const handlePrevious = () =>{

    if(postSlug == 1){
    if(unitSlug != 0 ){
    navigate("/tools/"+postSlug+"/area/"+areaSlug+"/Unit/"+(parseInt(unitSlug)-1));
    window.location.reload();
    }
    if (unitSlug == 0 && areaSlug != 1){
      navigate("/tools/"+postSlug+"/area/"+(parseInt(areaSlug)-1)+"/Unit/0");
      window.location.reload();
    }

    
  }else{
    if(unitSlug != 0 ){
      navigate("/tools/"+postSlug+"/area/"+areaSlug+"/Unit/"+(parseInt(unitSlug)-1));
      window.location.reload();
      }
      if (unitSlug == 0 && areaSlug != 9){
        navigate("/tools/"+postSlug+"/area/"+(parseInt(areaSlug)-1)+"/Unit/0");
        window.location.reload();
      }
  
  }
}

  const handleSkip=()=>{



if(postSlug == 1){

    if(unitSlug == (areaLength-1)&& areaSlug != 8){
      unitSlug = 0;
      areaSlug = parseInt(areaSlug)+1;
    navigate("/tools/"+postSlug+"/area/"+areaSlug+"/Unit/"+(parseInt(unitSlug)));
    }
    if(unitSlug == (areaLength-1) && areaSlug == 8){
      navigate(`/tools/${postSlug}/summary/${postSlug > 1 ? `9`:`1`}`);
      window.location.reload();

    }
    else{
      navigate("/tools/"+postSlug+"/area/"+areaSlug+"/Unit/"+(parseInt(unitSlug)+1));
      window.location.reload();

    }
  }

else{
  if(unitSlug == (areaLength-1)&& areaSlug != 13 ){
    unitSlug = 0;
    areaSlug = parseInt(areaSlug)+1;
  navigate("/tools/"+postSlug+"/area/"+areaSlug+"/Unit/0");
  window.location.reload();

  }
  else if(unitSlug == (areaLength-1) && areaSlug == 13){
    navigate(`/tools/${postSlug}/summary/${postSlug > 1 ? `9`:`1`}`);
    window.location.reload();

  }
  else{
    navigate("/tools/"+postSlug+"/area/"+areaSlug+"/Unit/"+(parseInt(unitSlug)+1));
    window.location.reload();


  }
  window.location.reload();
}
  }

  const handleSubmission = () => {
    		const formData = new FormData();
    
    		formData.append('upload', selectedFile);
        console.log(formData);
    
    		axios.post(`https://ohs.msabc.online/api/v1/portfolio/users/${userId}/tools/${postSlug}/areas/${areaSlug}/units/${unitId}/questions/${questionId}`,formData ,{
          headers:headersFile,
        })
    			.then((res) => {
            console.log(res);
            window.location.reload();
          })
    			.catch((error) => {
    				console.error('Error:', error);
    			});
    	};
    	;

  const fetchRadio=async()=>{
    console.log(unitId);
    axios.get(`https://ohs.msabc.online/api/v1/responses/users/${userId}/tools/${postSlug}/areas/${areaSlug}/units/${unitId}`,{
      headers:headers}
      ).then
    ((res) =>{
        console.log(res);
      if(res.data[0]){
        setIsPatch(true);
        setExistingValue(res.data[0].choiceId);
        if(postSlug == 1){
        var temp = res.data[0].choiceId;
        console.log(temp);
        setRadioValue(temp);
        }else{
            console.log("Answers -- ", res.data);
            var tempArr = res.data
            tempArr.sort(function(a, b) { 
              return a.questionId - b.questionId ;
            });

            const output = Object.fromEntries(tempArr.map(({
              questionId,
              choiceId,
              responseText
            }) => [questionId, (responseText == null) ? choiceId: responseText ]))
            console.log(output);
            setLoadValue(output);

        }

      }
      else{
        console.log("No data");
      }

        // console.log(res.data);

    }).catch(error =>{
      console.log("Error is _____ ",error);
    })
}

const helperData=(data)=>{
    console.log(data);
    Object.keys(data).forEach(function(key) {
        console.log(data[key]);
    });
    setData(JSON.stringify(data));
    handleSubmit2(JSON.stringify(data));



    

}

const fetchProgress = () =>{
  axios.get(`https://ohs.msabc.online/api/v1/reports/progress/tools/${postSlug}/areas/${areaSlug}/users/${userId}`,{
    headers:headers}).then
  ((res) =>{
      var tempData = res.data;
      var progress = tempData.progress;
      var maxComplete = tempData.maxCompletion;
      var percent = (progress / maxComplete ) * 100;
      console.log(percent);
      setProgress(percent);
  }).catch(error =>{
    console.log("Error is -----",error.response.data.message);
    var err = error.response.data.message;
    if(err == "jwt expired"){
      console.log("log out");
    }
})
}


    const fetchData=async()=>{
        axios.get(`https://ohs.msabc.online/api/v1/tools/${postSlug}/areas/${areaSlug}`).then
        ((res) =>{
            const allUnit = res.data.units[unitSlug]; 
            const questionObj = res.data.units[unitSlug].questions[page].choices;
            const id = res.data.units[unitSlug].questions[page].questionId;
            const questions = res.data.units[unitSlug].questions[page].questionText;
            const allQuestion = res.data.units[unitSlug].questions;
            const tempUnitId = res.data.units[unitSlug].unitId;
            const tempArea = res.data;
            console.log(res.data.units.length);
            setAreaLength(res.data.units.length);
            setUnitId(tempUnitId);
            setAllQuest(allQuestion);
            setQuestion(questions);
          //  console.log(allQuestion);
            setQuestionOb(questionObj);
            setUnits(allUnit);
            setArea(tempArea);
            var abc = allUnit.description;


            // var progress = tempData.progress;
            // var maxComplete = tempData.maxCompletion;
            // var percent = (progress / maxComplete ) * 100;
            // console.log(percent);
            // setProgress(percent);
           
            var words = abc.split(" ");
            var datacont="";
           
           
            for (var i = 0; i < parseInt(words.length/3) - 1; i++) {
               // words[i] += " ";
                datacont += words[i]+' ';
            }
            

            var words1 = abc.split(" ");
            var datacont1="";
           
            for (var i = parseInt(words1.length/3)-1; i < words1.length; i++) {
              datacont1 += words1[i]+' ';
            }
            setUnits1(datacont);
            setUnits2(datacont1);

          //  console.log(questionObj);
            setQuestionId(id);
          //   console.log(questions);

          fetchProgress();

    
        })
    }

    const deleteFile = (pId) =>{
      axios.delete(`https://ohs.msabc.online/api/v1/portfolio/users/${userId}/tool/${postSlug}/portfolio/${pId}`,{headers}).then
      ((res) =>{
          const tempUsers = res.data; 
          console.log(tempUsers);
          window.location.reload();
          // fetchUser();
  
      })
    }

    const fetchFiles = () =>{
      axios.get(`https://ohs.msabc.online/api/v1/portfolio/users/${userId}/tools/${postSlug}`,{
        headers:headers}).then
      ((res) =>{
         console.log(res.data);
         var temp = res.data;
         temp.map((d,id)=>{
          if(d.unitId == unitId){
            tempArrFile.push(d) ;
          }
         })
         console.log(tempArrFile);
         setArrFiles(tempArrFile);
         setIsFile(true);
      })
      // console.log(tempArrFile);
    
    }


    useEffect(()=>{ 

  if(unitId != 0){
    fetchRadio();
    fetchFiles();
  }

    },[unitId])
    React.useEffect(()=>{

      fetchData();
      

    },[])

    useEffect(()=>{
      if(loadValue){
        for (var k in loadValue){
          if(Array.isArray(loadValue[k])){
            console.log("is array",loadValue[k]);
            setValue(k,loadValue[k].map(String));
          }else{
          setValue(k,""+loadValue[k]);
          }
        }
        // setValue("2","2");
        // setValue("3","Hello");
        // setValue("4",["1","2"])
        // Object.entries(loadValue).forEach(
        //   ([name, value]) => setValue(name, value));
      
        console.log(loadValue);
      }
    },[loadValue])

    //var secondToolQuestion="";
    const toolTwoQuestions = allQuest?.map((data,id)=>{

        // console.log(loadValue[id]);
    //   var secondToolQuestion=<h3>{data.questionText}</h3>; 
    // <h3 class="font-bold text-xl"><span class="text-blue">{data.questionText} Hi</span> </h3> 
    console.log(data);
return <><h3 class="font-bold text-xl"><span class="text-blue"> Question {id+1}.</span> {data.questionText}</h3>
<div class="relative flex flex-wrap items-center bg-extralightblue w-full py-6 px-9  text-left border-0 rounded-xl transition focus:outline-none text-xl" type="button">

{data.questionType == 'likert' ? 
    data.choices.map((item, index) => {
       return<> 
        <div class="p-2">
        <p class="prose text-base">{parse(item.choiceText)}</p>
        <label class="unit-radio big ml-auto mr-auto mt-2 table">
          {/* <input type="radio" value={item.value} name="ranking" onChange={handleChange} checked={loadValue[id]?.choiceId==item.value}/> */}
          <input type="radio"  value={item.value} {...register(""+data.questionId)}   onChange={(e) => {}}/>

          {/* <input type="radio"  {...register("firstName")} placeholder="First name" /> */}

          <span class="checkmark"></span> </label>
      </div>
      </>
    })
   

: data.questionType == 'userInput'? 
<> <div class="relative flex flex-wrap items-center bg-extralightblue w-full py-6 px-9  text-left border-0 rounded-xl transition focus:outline-none text-xl" type="button">

            <div class="flex flex-wrap w-full mt-4 ">
              <textarea class="w-full bg-white py-4 px-8 block shadow-md rounded-2xl w-full form-control block w-full text-base font-normal text-black border border-solid border-extralightblue rounded-xl transition ease-in-out m-0 focus:border-blue focus:outline-none" rows="6" placeholder="Enter your answer..." {...register(""+data.questionId)}></textarea>
            </div>
                   </div>
       </> 

:data.questionType == 'checkbox'?
data.choices.map((item, index) => {
    return<> 
          <button class="checkbox-button relative flex items-center bg-extralightblue w-full py-6 pl-14 pr-6  text-left border-0 rounded-xl transition focus:outline-none hover:bg-blue hover:text-white font-bold text-xl collapsed mt-4" type="button">
          <label class="unit-checkbox">
            <input type="checkbox" value={item.value}  {...register(""+data.questionId)} />
            <span class="checkmark"></span> </label>
            <article class="prose">{parse(item.choiceText)}</article>
          </button>
   </>
 })

:data.questionType == 'slider' ?
<> <div class="flex flex-wrap bg-white py-4 px-8 block shadow-md rounded-2xl w-full mt-4 text-center justify-between overflow-x-auto">
              <div class="flex justify-between w-full whitespace-nowrap">
                <div class="p-2">
                  <p class="text-base">Strongly Disagree</p>
                </div>
                <div class="p-2">
                  <p class="text-base">Disagree</p>
                </div>
                {/* <div class="p-2">
                  <p class="text-base">Neutral</p>
                </div> */}
                {/* <div class="p-2">
                  <p class="text-base">Agree </p>
                </div>
                <div class="p-2">
                  <p class="text-base">Strongly Agree</p>
                </div> */}
              </div>
              <div class="relative pt-1 min-w-[472px] w-full">
                <input type="range" min={data.choices.min} max={data.choices.max} {...register(""+data.questionId)}   class="form-range appearance-none w-full h-6 p-0 bg-transparent focus:outline-none focus:ring-0 focus:shadow-none" id="customRange1"/>
              </div>
            </div></>
:data.questionType == 'radio' ?
data.choices.map((item, index) => {
  return<> 
   <div class="p-2">
   <p class="prose text-base">{parse(item.title)}</p>
   <label class="unit-radio big ml-auto mr-auto mt-2 table">
     {/* <input type="radio" value={item.value} name="ranking" onChange={handleChange} checked={loadValue[id]?.choiceId==item.value}/> */}
     <input type="radio"  value={item.value} {...register(""+data.questionId)}   onChange={(e) => {}}/>

     {/* <input type="radio"  {...register("firstName")} placeholder="First name" /> */}

     <span class="checkmark"></span> </label>
 </div>
 </>
})
            :
            questionOb?.map((data,id)=>{
              return <><div class="accordion-item bg-white shadow-md mt-4 rounded-xl  overflow-hidden">
            <h2 class="accordion-header mb-0" id="heading-1">
              <button class="accordion-button relative collapsed flex items-center bg-extralightblue w-full
                py-6 px-14 text-left border-0 rounded-xl transition focus:outline-none hover:bg-blue hover:text-white font-bold text-xl" type="button" data-bs-toggle="collapse" data-bs-target={"#collapse-"+id} aria-expanded="false"
                aria-controls={"collapse-"+ id}><label class="unit-radio">
     <input type="radio"  value={data.value} {...register(""+data.questionId)}   onChange={(e) => {}}/>
                  <span class="checkmark"></span> </label> {data.title} </button>
            </h2>
            <div id={"collapse-"+ id} class="accordion-collapse collapse" aria-labelledby="heading-1"
              data-bs-parent={"#accordion-1"}>
              <div class="accordion-body py-5 md:px-14 px-6"> 
        
                <div class="flex flex-wrap">
                  <div class="prose float-left w-full columns-2">
                   
                      {/* <li> Relevant legislation, regulations, guidelines, and  standards that pertain to workplace hazards.</li>
                      <li>Primary types of hazards:
                        <ul class="list-line">
                          <li>Physical</li>
                          <li>Ergonomic </li>
                          <li>Chemical </li>
                          <li>Biological </li>
                          <li>Psychological</li>
                        </ul>
                      </li> */}
                      {/* {data.choiceText} */}
                      {data.title? <article class="prose">{parse(data.choiceText)}</article>: null}
        
                  </div>
                  {/* <div class="float-left w-full md:w-1/2 md:pl-6">
                    <ul class="list-disc list-inside">
                      <li>The purpose and process of a job safety analysis</li>
                      <li>Hazard analysis methods such as:
                        <ul class="list-line">
                          <li>Failure Modes and Effects Analysis (FMEA) </li>
                          <li>Hazard and Operability Study (HAZOP) </li>
                          <li>Fault Tree Analysis (FTA)</li>
                          <li>Bowtie Analysis</li>
                        </ul>
                      </li>
                    </ul>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
            </>
            })
}
 </div>

</>
     
    
})

  const questionChoices = questionOb?.map((data,id)=>{
    return<> <div class="accordion-item bg-white shadow-md mt-4 rounded-xl  overflow-hidden">
    <h2 class="accordion-header mb-0" id="heading-1">
      <button class="accordion-button relative collapsed flex items-center bg-extralightblue w-full
        py-6 px-14 text-left border-0 rounded-xl transition focus:outline-none hover:bg-blue hover:text-white font-bold text-xl" type="button" data-bs-toggle="collapse" data-bs-target={"#collapse-"+id} aria-expanded="false"
        aria-controls={"collapse-"+ id}><label class="unit-radio">
          <input type="radio" name={"unit"} value={data.value} onChange= {handleChange} checked={radioValue==data.value}/>
          <span class="checkmark"></span> </label> {data.title} </button>
    </h2>
    <div id={"collapse-"+ id} class="accordion-collapse collapse" aria-labelledby="heading-1"
      data-bs-parent={"#accordion-1"}>
      <div class="accordion-body py-5 md:px-14 px-6"> 

        <div class="flex flex-wrap">
          <div class="prose float-left w-full columns-2">
           
              {/* <li> Relevant legislation, regulations, guidelines, and  standards that pertain to workplace hazards.</li>
              <li>Primary types of hazards:
                <ul class="list-line">
                  <li>Physical</li>
                  <li>Ergonomic </li>
                  <li>Chemical </li>
                  <li>Biological </li>
                  <li>Psychological</li>
                </ul>
              </li> */}
              {/* {data.choiceText} */}
              {data.title? <article class="prose">{parse(data.choiceText)}</article>: null}

          </div>
          {/* <div class="float-left w-full md:w-1/2 md:pl-6">
            <ul class="list-disc list-inside">
              <li>The purpose and process of a job safety analysis</li>
              <li>Hazard analysis methods such as:
                <ul class="list-line">
                  <li>Failure Modes and Effects Analysis (FMEA) </li>
                  <li>Hazard and Operability Study (HAZOP) </li>
                  <li>Fault Tree Analysis (FTA)</li>
                  <li>Bowtie Analysis</li>
                </ul>
              </li>
            </ul>
          </div> */}
        </div>
      </div>
    </div>
  </div>

  
  </>



})


    if (loading) {
      return <p>Data is loading...</p>;
    }


    return (
        < >
        <section class="inner-banner md:pt-9 md:pb-12 pt-8 pb-8 border-b border-gray-100 bg-white">
  <div class="container max-w-7xl ml-auto mr-auto">
  <nav class="rounded-md w-full">
      <ol class="list-reset flex flex-wrap">
        <li><a href="/" class="text-lightgray hover:text-blue">All Tools</a></li>
        <li><span class="text-lightgray px-2">/</span></li>
        { (postSlug == 1) ? 
        <li><a href="/tools/1" class="text-lightgray hover:text-blue">Self Assessment Tool</a></li> :
        <li><a href="/tools/2" class="text-lightgray hover:text-blue">Needs Assessment Tool</a></li>
      }
        <li><span class="text-lightgray px-2">/</span></li>
        <li><a href={`/tools/${postSlug == 1 ? `1`:`2`}/area/${area.areaId}`} class="text-lightgray hover:text-blue">{area.title}</a></li>
        <li><span class="text-lightgray px-2">/</span></li>
        <li><a href="" class="text-lightgray hover:text-blue">{units.unitId}</a></li>

      </ol>
    </nav>
    <div class="flex flex-wrap mt-10">
      <div class="float-left w-full columns-2">
        <p class="prose font-bold text-4xl text-blue mb-4">{units.unitId}</p>
        <h1 class="prose text-3xl md:text-4xl font-bold mb-4">{units.title}</h1>
      {units.title? <p class="prose">{parse(units.description)}</p>: null}

      </div>
      {/* <div class="float-left w-full md:w-7/12 pt-5 md:pl-9 md:pt-10">
        {units.title? <p class="prose">{units2}</p>: null}
      
      </div> */}
    </div>
  </div>
</section>



  <section class="common-content md:pt-12 md:pb-16 pt-8 pb-8 bg-white overflow-hidden">
  <div class="container max-w-7xl ml-auto mr-auto">
    <div class="w-full mb-5 text-right px-2">
      <label class="bg-extralightblue text-black py-2 sm:px-7 px-4 rounded-lg  hover:text-blue">
    <input  type="file" name="upload" onChange={changeHandler}  />
    
    </label>
			{isFilePicked ? (
        <>
				<div class="mt-3">
        <a onClick={handleSubmission} class="bg-extralightblue text-blue py-2 sm:px-7 px-4 rounded-lg hover:bg-black hover:text-white mt-2">Upload<i class="ml-2 fa fa-upload"></i></a>
				</div>

        </>
		) : (
				null
		)}
    {/* <p>{arrFiles[0]?.title}</p> */}
    {arrFiles.length > 0 ? 
    <p class="mt-2"><a class="hover:text-blue " href={arrFiles[0]?.hyperlink}>{arrFiles[0]?.title}</a> <a onClick={()=>{deleteFile(arrFiles[0].portfolioId)}}><i class="hover:text-red fa fa-trash"></i></a></p>
:null
  }
    <div>
			{/* <button onClick={handleSbmission}>Submit</button> */}

			</div>
    {/* <a onClick={handleSubmission} class="bg-extralightblue text-black py-2 sm:px-7 px-4 rounded-lg hover:bg-black hover:text-white">Upload <i class="fa fa-upload"></i></a> */}
  </div>

    <div id="unitslider" class="carousel slide relative accordion-wrap px-2 overflow-hidden" data-bs-ride="carousel">
      <div class="">
        <div class="">
          
          {(postSlug == 1)? <>
            
                    <p class="prose">{(question)}</p>
                    <div class="accordion" id={"accordion-1"}>
                    {questionChoices}
                           </div> </>:
                               <form id="myform" onSubmit={handleSubmit((data) => helperData(data))}>
                    {toolTwoQuestions}
                           {/* <h3>{toolTwoQuestions}</h3> */}
                           </form>
                           
                           }

        </div>
<div class="flex justify-between pt-6">

        {/* <button class=" items-center justify-center hover:outline-none hover:no-underline focus:outline-none focus:no-underline flex left-0 font-ProximaNovaRg font-normal py-1 leading-6 sm:px-6 px-4 border-darkgray rounded-xl text-white bg-blue hover:bg-darkblue opacity-100 w-auto" type="button"  data-bs-slide="prev"> <span class=" inline-block bg-no-repeat w-5" aria-hidden="true"></span> <span class="">Prev</span> </button> */}
       {postSlug == 1 ?  unitSlug == 0 && areaSlug == 1 ? 
null :      
  <a onClick={()=>handlePrevious()} class="bg-extralightblue text-black py-2 sm:px-7 px-4 rounded-lg hover:bg-black hover:text-white">Prev</a>
 
       :
       unitSlug == 0 && areaSlug == 9 ? 
      null:
      <a onClick={()=>handlePrevious()} class="bg-extralightblue text-black py-2 sm:px-7 px-4 rounded-lg hover:bg-black hover:text-white">Prev</a>

      }


        <a onClick={()=>handleSkip()} class="bg-extralightblue text-black py-2 sm:px-7 px-4 rounded-lg hover:bg-black hover:text-white">Skip</a>
        {postSlug == 1 ?         <a onClick={()=>handleNext()} class="bg-extralightblue text-black py-2 sm:px-7 px-4 rounded-lg hover:bg-black hover:text-white">Save/Next</a>
:       <input type="submit" form="myform" value="Next" />
}

        {/* <button class=" items-center justify-center hover:outline-none hover:no-underline focus:outline-none focus:no-underline flex left-0 font-ProximaNovaRg font-normal py-1 leading-6 sm:px-6 px-4 border-darkgray rounded-xl text-white bg-blue hover:bg-darkblue opacity-100 w-auto" type="button" 
        data-bs-slide="next"><span class="">Next</span> <span class="carousel-control-next-icon inline-block bg-no-repeat w-5" aria-hidden="true"></span></button>
 */}
      </div>
      {/* <div class="carousel-indicators flex p-0 w-full m-0 mt-6 bg-extralightblue rounded-2xl">
        <button type="button" data-bs-target="#unitslider" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
        <button type="button" data-bs-target="#unitslider" data-bs-slide-to="1" aria-label="Slide 2"></button>      
      </div> */}

      <div class="w-full flex p-0 mt-6 h-5 mb-6 bg-extralightblue rounded-2xl">
      <div class="bg-sky-400 h-5 rounded" style={{width: progress+"%"}}></div>
    </div>
    </div>
  </div>
  </div>
</section>
       </>
       );
  };


  const UnitDetail = () => {
    return (
      <>
        <UnitDetails />


      </>
    );
  };
  
  export default UnitDetail;