import React, { useEffect, useState } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import axios from 'axios';
import { ValidationForm, TextInput } from "react-bootstrap4-form-validation";
import jwt_decode from "jwt-decode";
import qs from "qs";
import parse from "html-react-parser";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



const SignInComp = () => {

    const [data,setData] = useState({
        email:"",
        password:"",
        phone_number:"",
        full_name:"",
    });
    const [dataR,setDataR] = useState({
      email:"",
  });
  var timer;
    const{postSlug} = useParams();
    const [isForgot,setIsForgot] = useState(false);
    
    const navigate = useNavigate();
    const headers = {
      
        'Content-Type': 'application/x-www-form-urlencoded',
        
      }
      const headers2 = {
        'Content-Type': 'application/x-www-form-urlencoded',
        
      }
    const [signInC,setSignInC] = useState({});



    const handleChange = (e) => {
        const value = e.target.value;
        setData({
          ...data,
          [e.target.name]: value
        });
      };
      const handleChangeR = (e) => {
        const value = e.target.value;
        setDataR({
          ...dataR,
          [e.target.name]: value
        });
      };
      const fetchLogin=async()=>{
        axios.get('https://ohs.msabc.online/api/v1/content/page/login').then
        ((res) =>{
            const allIntro = res.data[0]; 
            console.log(allIntro);
            setSignInC(allIntro);
        })
    }

    React.useEffect(()=>{
      fetchLogin();

  },[])
  const onSubmitFormR=async(e) =>{
    e.preventDefault();
    const userDataR = qs.stringify({   
    email_to: dataR.email,

    
    });
    console.log(userDataR);
    axios.post('https://ohs.msabc.online/api/v1/email/forgot',userDataR,{
        headers:headers
    })
    .then((res) => {
      console.log(res.data);
      toast.success('🦄 Passsword reset link sent on email!', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
        setIsForgot(false);

    })
  }


  const logOut =() =>{
    localStorage.clear();
    window.location.pathname = "/";
}

    const onSubmitForm=async(e) =>{
        e.preventDefault();
        const userData = qs.stringify({   
        username: data.email,
        password: data.password,
        grant_type: 'password',
        client_id:'null',
        client_secret:'null'
        
        });
        console.log(userData);
        axios.post('https://ohs.msabc.online/auth/login',userData,{
            headers:headers
        })
        .then((res) => {
            console.log(res);
                localStorage.setItem("safety_alliance_user", res.data);
                localStorage.setItem("safety_alliance_token", res.data.access_token);
                localStorage.setItem("token_expiry", res.data.expires_in);
                localStorage.setItem("isAuthenticated", "true");
                var token = localStorage.getItem("safety_alliance_token");
                
                console.log(token);
                try{
                var decoded = jwt_decode(token);
                console.log(decoded);
                localStorage.setItem("safety_alliance_userId", decoded.id);
                localStorage.setItem("is_Admin", decoded.isAdmin);




                } catch (error){
                  console.log(error);
                }
      
          
                // setTimeout(()=> {
                //   console.log("hello WOrld.....")
                // }, 10000);

                window.location.reload();
                toast.success('🦄 Login Successful!', {
                  position: "top-center",
                  autoClose: 2000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                  });
                  setIsForgot(false);

                  
     
            
        })
        .catch(error =>{
            console.log("Error is -----",error);
            // alert("Passwords don't match");
            if(error.response.status == 400){
              toast.warn('🦄 Please check your Username/Password', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                });

            }

        })


    }


    // const fetchData=async()=>{
    //     axios.get(`https://ohs.msabc.online/api/v1/tools/${postSlug}/areas`).then
    //     ((res) =>{
    //         const allTools = res.data; 
    //         setSignInC(allTools);
    //         console.log(allTools);
    //         // console.log(res.data);
    
    //     })
    // }

    // useEffect(()=>{
    //     // document.body.classList.add('green-pattern');
    // })
    // React.useEffect(()=>{
    //     // fetchData();
    // },[])

    return (
        <>
     <div class="modal fade fixed top-0 left-0  hidden w-full h-full outline-none overflow-x-hidden overflow-y-auto"
      id="LoginPopup" tabindex="-1" aria-labelledby="user popup" aria-hidden="true">
      <div class="modal-dialog modal-xl relative w-auto pointer-events-none">
        <div
          class="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-xl outline-none text-current overflow-hidden">
          <div class="modal-body relative">
            <button type="button"
              class="btn-close absolute right-5 top-5 font-bold box-content w-4 h-4 p-0 border border-black text-black rounded-full opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline leading-4"
              data-bs-dismiss="modal" aria-label="Close" onClick={()=>{setIsForgot(false)}}>X</button>
            <div class="flex flex-wrap">
              <div class="float-left w-full p-6 lg:w-1/2 lg:p-12 green-gradient flex items-center justify-center">
                <div class="p-2">
                  <img src={process.env.PUBLIC_URL+"/img/popup-slide-1.png"} width="470" height="582" alt="" draggable="false"
                    class="ml-auto mr-auto shadow-xxl rounded-tl-[47%] rounded-tr-[47%]"/>
                    {signInC.prose ? <h3 class="font-bold text-lg text-gray-300 text-center mt-6">{parse(signInC.prose)} </h3>: null}
                  
                  <p class="text-center text-gray-300"></p>
                  <p class="text-black text-xs text-center lg:mt-12 mt-7">© 2022 Safety Alliance. All Rights Reserved
                  </p>
                </div>
              </div>

{ isForgot ? 
  <div class="float-left w-full lg:w-1/2 p-6 lg:p-12 text-left">
                <ValidationForm onSubmit={onSubmitFormR} >
                <div class="mb-6 lg:mb-24 w-full">
                  <p class="popup-logo"><img src={process.env.PUBLIC_URL+"/img/popup-logos.png"} width="264" height="38" alt=""/></p>
                </div>
                {signInC.title ?<h3 class="text-3xl md:text-4xl font-bold mb-2">Reset Password</h3> : null}
                {signInC.prose ?<p class="mb-8">Enter you email to reset password</p> : null
}
                <div class="flex flex-wrap">
                  <div class="mb-4 w-full">
                    <label class="form-label inline-block mb-2 font-bold text-lg">Email</label>


                      <TextInput name="email" class="form-control block w-full px-3.5 py-2.5 text-base font-normal text-black bg-extralightblue border border-solid border-extralightblue rounded-xl transition ease-in-out m-0 focus:border-blue focus:outline-none"
                      id="email" value={dataR.email} onChange={handleChangeR} type="email" placeholder="Enter your email"/>

                  </div>
                </div>

                <div class="flex flex-wrap">
                  <div class="mb-4 w-full flex flex-wrap justify-between items-center">
                    <div class="form-check mr-3">

                    </div>
                    <a onClick={()=>{setIsForgot(!isForgot)}} class="font-bold text-lightblue hover:text-blue">Back to Sign In</a>
                  </div>
                </div>
                <div class="flex flex-wrap">
                  <div class="mb-4 mt-6 w-full text-center">
                    <input type="submit" class="btn-primary font-bold cursor-pointer min-w-[200px]" value="Submit"/>
                  </div>
                </div>
                <p class="w-full text-center mt-4">Don’t have an account? <a href="#"
                data-bs-toggle="modal" data-bs-target="#SignupPopup" class="text-lightblue hover:text-blue">Sign up</a> </p>

                  </ValidationForm>

              </div>

:
              <div class="float-left w-full lg:w-1/2 p-6 lg:p-12 text-left">
                <ValidationForm onSubmit={onSubmitForm} >
                <div class="mb-6 lg:mb-24 w-full">
                  <p class="popup-logo"><img src={process.env.PUBLIC_URL+"/img/popup-logos.png"} width="264" height="38" alt=""/></p>
                </div>
                {signInC.title ?<h3 class="text-3xl md:text-4xl font-bold mb-2">{parse(signInC.title)}</h3> : null}
                {signInC.prose ?<p class="mb-8">{parse(signInC.prose)}</p> : null
}
                <div class="flex flex-wrap">
                  <div class="mb-4 w-full">
                    <label class="form-label inline-block mb-2 font-bold text-lg">Email</label>


                      <TextInput name="email" class="form-control block w-full px-3.5 py-2.5 text-base font-normal text-black bg-extralightblue border border-solid border-extralightblue rounded-xl transition ease-in-out m-0 focus:border-blue focus:outline-none"
                      id="email" value={data.email} onChange={handleChange} type="email" placeholder="Enter your email"/>

                  </div>
                </div>
                <div class="flex flex-wrap">
                  <div class="mb-4 w-full">
                    <label class="form-label inline-block mb-2 font-bold text-lg">Password</label>

                      <TextInput name="password" class="form-control block w-full px-3.5 py-2.5 text-base font-normal text-black bg-extralightblue border border-solid border-extralightblue rounded-xl transition ease-in-out m-0 focus:border-blue focus:outline-none" id="password" value={data.password} onChange={handleChange} type="password" placeholder="Enter your password"/>

                  </div>
                </div>
                <div class="flex flex-wrap">
                  <div class="mb-4 w-full flex flex-wrap justify-between items-center">
                    <div class="form-check mr-3">
                      <input
                        class="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                        type="checkbox" value="" id="flexCheckDefault"/>
                      <label class="form-check-label inline-block text-gray-800" for="flexCheckDefault">
                        Remember Password
                      </label>
                    </div>
                    <a onClick={()=>{setIsForgot(!isForgot)}} class="font-bold text-lightblue hover:text-blue">Forgot Password</a>
                  </div>
                </div>
                <div class="flex flex-wrap">
                  <div class="mb-4 mt-6 w-full text-center">
                    <input type="submit" class="btn-primary font-bold cursor-pointer min-w-[200px]" value="Sign in"/>
                  </div>
                </div>
                <p class="w-full text-center mt-4">Don’t have an account? <a href="#"
                data-bs-toggle="modal" data-bs-target="#SignupPopup" class="text-lightblue hover:text-blue">Sign up</a> </p>

                  </ValidationForm>

              </div>

              }
            </div>
          </div>
        </div>
      </div>
    </div>

    <ToastContainer
position="top-center"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="light"
/>

       </>
    );
  };
  const SignIn = () => {
    return (
      <>
        <SignInComp/>
      </>
    );
  };
  
  export default SignIn;