import React, { useEffect, useState } from "react";
import { useParams, Link,useNavigate, Navigate } from "react-router-dom";
import axios from 'axios';
import { data } from "jquery";
import Pagination from "react-js-pagination";
import parse from 'html-react-parser';




const SummaryDetails = () => {
    const [isLoading,setIsLoading]= useState(true);
    const [allAreas,setAllAreas] = useState([]);
    const{postSlug} = useParams();
    var {areaSlug}= useParams();
    const [unitData,setUnitData] = useState([]);
    const [eachUnit,setEachUnit] = useState([]);
    const [lorem,setLorem] = useState({});
    const [activePage,setActivePage] = useState(1);
    const [reportUnit,setReportUnit] = useState([]);
    const arr = [];
    var userId = localStorage.getItem("safety_alliance_userId");
    var token = localStorage.getItem("safety_alliance_token");
    const headers = {
      'accept': '*/*',
      'Authorization': `Bearer ${token}`
      
    }
    const headers2 = {
      'accept': '*/*',
      
    }

    const navigate = useNavigate();



    // let data=[];

    const fetchData=async()=>{
        axios.get(`https://ohs.msabc.online/api/v1/tools/${postSlug}/areas/${areaSlug}`).then
        ((res) =>{
            const allAreasT = res.data.units; 
            console.log(allAreasT);
            const temData = res.data;
            setUnitData(temData);
            setAllAreas(allAreasT);
            // console.log(res.data);
            fetchResponses();
            setIsLoading(false);
            fetchLorem();
        })
        // setUnitData(data);
    }
    const fetchLorem=()=>{
      axios.get('https://ohs.msabc.online/api/v1/content/page/summary').then
      ((res) =>{
          var temp = res.data[0];
          setLorem(temp);

      })
  }




    const fetchResponses = () =>{
      axios.get(`https://ohs.msabc.online/api/v1/responses/users/${userId}/tools/${postSlug}/areas/${areaSlug}`,{headers}).then
      ((res)=>{
        console.log(res.data);
        var temp = res.data;
        // setReportUnit(temp);
        temp.map((data,id)=>{
          if(data.portfolio.length == 0){
            arr[id] = false;

          }else{
            arr[id] =true;
          }
        })
        console.log(arr);
        setReportUnit(arr);

      })
    }

    // const fetchUnits=async()=>{
    //     axios.get(`https://ohs.msabc.online/api/v1/tools/1/areas/1`).then
    //     ((res) =>{
    //         console.log(res.data.units);
    //         const units = res.data.units;
    //         setUnitData(units);
             
    //         return units;
            
            
    //         // console.log(res.data);
    
    //     })
    // }
    const handleNext= () =>{
      if(postSlug == 1){
      if(areaSlug < 8){
      areaSlug = parseInt(areaSlug)+1;
      navigate("/tools/"+postSlug+"/summary/"+areaSlug);
      window.location.reload();
      }


    }else{
      if(areaSlug < 13){
        areaSlug = parseInt(areaSlug)+1;
        navigate("/tools/"+postSlug+"/summary/"+areaSlug);
        window.location.reload();
        }
    }
  }
    const handlePrevious=()=>{
      if(postSlug == 1){
      if(areaSlug != 1){
      areaSlug = parseInt(areaSlug)-1;
      navigate("/tools/"+postSlug+"/summary/"+areaSlug);
      window.location.reload();
      }
    }else{
      if(areaSlug != 9){
        areaSlug = parseInt(areaSlug)-1;
        navigate("/tools/"+postSlug+"/summary/"+areaSlug);
        window.location.reload();
        }
    }
  }

  const getReport=()=>{
    axios.post(`https://ohs.msabc.online/api/v1/reports/{reportType}/tools/${postSlug}/users/${userId}/download`,"",{headers}).then
    ((res) =>{
    console.log(res.data);


    })
  }

    useEffect(()=>{
        fetchData();


        console.log("Onceeeeee------------------");    },[])
    // React.useEffect(()=>{
    //     fetchData();
    //     console.log(data);
    //     console.log("Onceeeeee------------------");


    // },[])

    const area = allAreas.map((a,index)=>{
                return <div class="relative p-5 pt-4 pb-16 rounded-2xl shadow-xs bg-extralightblue">
                <h3 class="flex justify-between font-bold text-blue text-['17px'] mb-2"><a href="#">{a.unitId}</a> 
                 <i class="w-6 block min-w-[28px]">
                 {reportUnit[index] ? 
  <img src={process.env.PUBLIC_URL+"/img/pin-small.png"} alt=""/>
  : null
    }                  </i>
                  </h3>
                 <p class="font-bold text-black leading-snug">
                    {a.title}
                 </p>
                 { postSlug == 1 ? 
                 <a href={"/tools/"+postSlug+"/area/"+areaSlug+"/Unit/"+(index)} target="_blank" class="absolute bottom-0 left-0 px-5 pb-6 right-0 flex items-center justify-end  mt-4" ><span class="text-black underline hover:no-underline pr-2">Edit Answer</span> <i class="w-4"><img src="img/pencil.png" alt=""/></i> </a>
                :  
                <a href={"./area/"+(index)+"/Unit/"+index} target="_blank" class="absolute bottom-0 left-0 px-5 pb-6 right-0 flex items-center justify-end  mt-4" ><span class="text-black underline hover:no-underline pr-2">Edit Answer</span> <i class="w-4"><img src="img/pencil.png" alt=""/></i> </a>

                }
                 {/* <a href={"./area/"+(id+1)+"/Unit/"+index}  class="absolute bottom-0 left-0 px-5 pb-6 right-0 flex items-center justify-end  mt-4" data-bs-toggle="modal" data-bs-target="#UnitPopup"><span class="text-black underline hover:no-underline pr-2">Edit Answer</span> <i class="w-4"><img src="img/pencil.png" alt=""/></i> </a> */}
                 </div>
                
            })



            {/* <p>{ unitData ? console.log(unitData[0]): null}</p> */}
            {/* {data[id].map((a,index)=>{
                              return( <div class="relative p-5 pt-4 pb-16 rounded-2xl shadow-xs bg-extralightblue">
                              <h3 class="flex justify-between font-bold text-blue text-['17px'] mb-2"><a href="#">{a.unitId}</a>  <i class="w-6 block min-w-[28px]"><img src="img/pin-small.png" width="22" height="22" alt=""/></i></h3>
                               <p class="font-bold text-black leading-snug">Demonstrate Understanding 
                                 of Hazards in The Work Place</p>
                                 
                               <a href="#" class="absolute bottom-0 left-0 px-5 pb-6 right-0 flex items-center justify-end  mt-4" data-bs-toggle="modal" data-bs-target="#UnitPopup"><span class="text-black underline hover:no-underline pr-2">Edit Answer</span> <i class="w-4"><img src="img/pencil.png" alt=""/></i> </a> </div>
                              )
            })} */}


    



if(isLoading){
    return <div>Loading</div>
}

    return (
        < >

<section class="inner-banner md:pb-14 pt-8 pb-8 border-b border-gray-100 bg-white">
    
  <div class="container max-w-7xl ml-auto mr-auto">
    <nav class="rounded-md w-full">
      <ol class="list-reset flex flex-wrap">
        <li><a href={`/`} class="text-lightgray hover:text-blue">All Tools</a></li>
        <li><span class="text-lightgray px-2">/</span></li>
        { postSlug == 1 ?
        <li><a href={`/tools/1`} class="text-lightgray hover:text-blue">Competency Assessment Tool</a></li>
        :
        <li><a href={`/tools/2`} class="text-lightgray hover:text-blue">Needs Assessment Tool</a></li>

}
        <li><span class="text-lightgray px-2">/</span></li>
        <li class="text-lightgray">Summary Report</li>
      </ol>
    </nav>
     <div class="flex flex-wrap items-center mt-8">
      <div class="float-left w-full md:w-1/2">
        <img src={lorem.image} width="635" height="423" alt="" class="block ml-auto mr-auto shadow-xxl"/>        
      </div>
      <div class="float-left w-full md:w-1/2 md:pl-12 pt-7 md:pt-0 text-center md:text-left">
        <p class="font-bold text-lightgray tracking-wider mb-4">{lorem.subtitle}</p>
        <h1 class="text-3xl md:text-4xl lg:text-5xl font-bold mb-4">{lorem.title}</h1>
        {lorem.prose ? <p class="mb-6">{parse(lorem.prose)}</p> : null}
        <a onClick={()=>{getReport()}} class="btn-primary">View Report</a> 

       </div>

    </div>
  </div>
</section>

{/* Modal */}
<section class="common-content md:pt-12 md:pb-16 pt-8 pb-8 bg-white">
  <div class="container max-w-7xl ml-auto mr-auto">
<div class="modal fade fixed top-0 left-0  hidden w-full h-full outline-none overflow-x-hidden overflow-y-auto"
  id="UnitPopup" tabindex="-1" aria-labelledby="unit-popup-1" aria-hidden="true">
  <div class="modal-dialog modal-fullscreen relative w-auto pointer-events-none">
    <div
      class="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-xl outline-none text-current">
      <div
        class="modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md">
        <h5 class="text-xl font-bold leading-normal text-blue" id="unit-popup-1">
          UNIT 101
        </h5>
        <button type="button"
          class="btn-close absolute right-5 top-5 font-bold box-content w-4 h-4 p-0 border border-black text-black rounded-full opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline leading-4"
          data-bs-dismiss="modal" aria-label="Close">X</button>
      </div>
      <div class="modal-body relative p-4">
        
      <iframe width="100%" height="100%" src={"./area/1/Unit/0"}></iframe>



      </div>
     
    </div>
  </div>
</div>

{/* Each Category */}
<div class="flex flex-wrap">
        <div class="float-left w-full columns-2">
          <div class="summary-data"> <i class="mb-3 block"><img src={unitData.icon} width="61" height="52" alt=""/></i>
            <h3 class="text-3xl md:text-4xl font-bold mb-3 text-blue">{unitData.title}</h3>
            {/* <p>{unitData.description}</p> */}
            {unitData.title? <article class="prose">{parse(unitData.description)}</article>: null}

          </div>
        </div>
       
        <div class="float-left w-full pt-5 md:pt-10">        
          <div class="flex flex-wrap grid gap-4 sm:grid-cols-2 lg:grid-cols-4 md:gap-6">
            
{area}
</div>
</div>
</div>
    <div class="flex justify-center mt-6 mb-7">
        {/* <button class=" items-center justify-center hover:outline-none hover:no-underline focus:outline-none focus:no-underline flex left-0 font-ProximaNovaRg font-normal py-1 leading-6 sm:px-6 px-4 border-darkgray rounded-xl text-white bg-blue hover:bg-darkblue opacity-100 w-auto" type="button"  data-bs-slide="prev"> <span class=" inline-block bg-no-repeat w-5" aria-hidden="true"></span> <span class="">Prev</span> </button> */}
        {areaSlug == 1 || areaSlug == 9 ? null :        <a onClick={()=>handlePrevious()} class="bg-extralightblue text-black py-2 sm:px-7 px-4 rounded-lg hover:bg-black hover:text-white mr-20">Previous</a>
}
        <a onClick={()=>handleNext()} class="bg-extralightblue text-black py-2 sm:px-7 px-4 rounded-lg hover:bg-black hover:text-white">Next</a>
</div>

</div></section>
       </>
    );
  };
  const Summary = () => {
    return (
      <>
        <SummaryDetails />

      </>
    );
  };
  
  export default Summary;