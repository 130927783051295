import React, { useEffect, useState } from "react";
import axios from 'axios';
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import SignIn from "../../components/Auth/SignIn";
import Register from "../../components/Auth/Register";
import $ from 'jquery';
import parse from "html-react-parser";





const Intro = () => {

    const [tools,setTools] = useState([]);
    const [intro,setIntro] = useState([]);

    
    const [showRegister,setShowRegister] = useState(true);
    const [isLogin,setIsLogin] = useState(false);
    const [image,setImage] = useState("")


    const fetchData=async()=>{
        axios.get('https://ohs.msabc.online/api/v1/tools').then
        ((res) =>{
            const allTools = res.data; 
            const tempImg =  res.data[0].image;
            setTools(allTools);
            console.log(allTools);
            console.log(tempImg);
            setImage(tempImg);
            // console.log(res.data);
    
        })
    }
    const fetchIntro=async()=>{
      axios.get('https://ohs.msabc.online/api/v1/content/page/index').then
      ((res) =>{
          const allIntro = res.data; 
          setIntro(allIntro);
      })
  }

    useEffect(()=>{
        document.body.classList.add('has-gradient');
    })
    React.useEffect(()=>{
        fetchIntro();
        fetchData();
        checkLogin();
    },[])

    const checkLogin   =() =>{
      const safety_user = localStorage.getItem("safety_alliance_user");
      if(safety_user){
          setIsLogin(true);
      }

  }

    const toolList = tools.map((data,id)=>{
        return <div class="p-7 md:pb-16 rounded-3xl shadow-xxl bg-white">
<div class="flex justify-between items-center mb-9"> <img src={data.icon} width="41" height="48" alt=""/> </div>
<p class="prose block font-ProximaNovaRg text-base font-bold mb-3 text-lightblue tracking-wider">{data.subtitle}</p>
<h3 style={{color: data.colour}} class={`prose text-[${data.colour}] text-3xl font-bold mb-2 `} > {data.title}</h3>
<p class="prose text-base mb-4"> {parse(data.description)}</p>
<p class="text-center mt-4 md:mt-9  " ><Link to={{pathname:`/tools/${data.id}`}} className="btn-primary ">View more</Link></p>
</div>  

    })
    const introList=intro.map((data,id)=>{
      if(id==0)
      {
      return <><small class="block font-ProximaNovaRg text-base font-bold mb-3 text-lightgray tracking-wider uppercase">{data.subtitle}</small>
      <h1 class="prose text-3xl md:text-5xl font-bold mb-4">{data.title}</h1>
      <article class="prose mb-6">{parse(data.prose)}</article>
      <p>{ (!isLogin)? <a href="#"  data-bs-toggle="modal"
      data-bs-target="#LoginPopup" class="btn-primary">Start Now</a>:
      <a href="/tools/1" class="btn-primary">Start Now</a> }</p></>
      }
      

    })
    const introList1=intro.map((data,id)=>{
      if(id==1)
      {
      return <><p class="block font-ProximaNovaRg text-base font-bold mb-3 text-lightgray tracking-wider uppercase">{data.subtitle}</p>
      <h2 class="prose text-3xl md:text-5xl font-bold mb-4">{data.title}</h2>
      <p class ="prose">{parse(data.prose)}</p></>
      }
      

    })




    return (
        < >
        <section class="main-banner pt-6 pb-8">
          <div class="container max-w-7xl ml-auto mr-auto">
            <div class="flex-wrap items-center grid md:grid-cols-2">
              <div class="mb-6"> <img src={image} width="447" height="552" alt="" class="block ml-auto mr-auto"/> </div>
              <div class="md:mb-6 md:ml-10 text-center md:text-left"> 
              {introList}
              </div>
            </div>
          </div>
        </section>

        <section class="competency-wrap md:pt-9 md:pb-28 pt-8 pb-8 border-t-2 border-gray-100">
          <div class="container max-w-7xl ml-auto mr-auto">
            <div class="w-full">
            {introList1}
            </div>
            <div class="flex-wrap grid md:grid-cols-2 gap-6 md:gap-10 mt-10 md:mt-14">
            {toolList}


            </div>
          </div>
        </section>

       
        {/* <SignIn/> */}

      {/* <Modal
      backdrop="static"
            size="md"
            dialogClassName="modal-40w"
            show={showRegister}
            onHide={() => setShowRegister(false)}
            centered>
        <Modal.Body> */}

       {/* </Modal.Body>
       </Modal> */}
        {/* <script src="js/jquery-3.3.1.min.js"></script> 
        <script src="js/jquery.flexslider.min.js"></script> 
        <script src="js/jquery.magnific.popup.min.js"></script> 
        <script src="js/jquery.combine.js"></script> */}
        </>
    );
  };
  const Home = () => {
    return (
      <>
        <Intro />
        <SignIn/>
       <Register/>

      </>
    );
  };
  
  export default Home;