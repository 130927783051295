import React, { useEffect, useState } from "react";
import { useParams, Link,useNavigate } from "react-router-dom";
import { ValidationForm, TextInput } from "react-bootstrap4-form-validation";
import axios from 'axios';

const AdminPage = () => {

    const [allUser,setAllUser] = useState([]);
    const [intro,setIntro] = useState([]);
    const navigate = useNavigate()

    const [editProfile,setEditProfile] = useState({});
    const [editUserId,setEditUserId] = useState(0);


    // const [data,setData] = useState({
    //     username:"",
    //     password:"",
    //     email:"",
    // });
    var token = localStorage.getItem("safety_alliance_token");

    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        // 'Content-Type': 'application/x-www-form-urlencoded'
        
      }
      const handleChange = (e) => {
        const value = e.target.value;
        setEditProfile({
          ...editProfile,
          [e.target.name]: value
        });
      };

    const [isHome, setIsHome] = useState(true);

    const [dataReport, setDataReport] = useState([]);


    
    const [showRegister,setShowRegister] = useState(true);
    const [isLogin,setIsLogin] = useState(false);
    var userId = localStorage.getItem("safety_alliance_userId");
    var{areaSlug}= useParams();
    const{postSlug} = useParams();

    const handleProfile = (data)=>{
      setIsHome(false);
      console.log(data);
      setEditProfile(data);
      var temp = data.id;
      console.log(temp);
      setEditUserId(temp);
      // getUserData();
    }

    const handleSwitch=(user)=>{
      localStorage.setItem("spoof_user",user);
      window.open("/", '_blank').focus();
      
    }

    const deleteUser=(user)=>{
      axios.delete(`https://ohs.msabc.online/api/v1/users/${user}`,{headers}).then
      ((res) =>{
          const tempUsers = res.data; 
          console.log(tempUsers);
          window.location.reload();
          // fetchUser();
  
      })
    }


    // const getUserData=()=>{
    //   axios.get(`https://ohs.msabc.online/api/v1/users/${editProfileId}`,{headers}).then
    //   ((res) =>{
    //       const tempUsers = res.data; 
    //       console.log(tempUsers);
    //       // fetchUser();
  
    //   })
    // }

    const fetchUser=async()=>{
        axios.get('https://ohs.msabc.online/api/v1/users',{headers}).then
        ((res) =>{
            const tempUsers = res.data; 
            const tempId = res.data.id;
            setAllUser(tempUsers);
            // fetchUser();
    
        })
    }
    // const fetchUser = () =>{
    //     axios.get(`https://ohs.msabc.online/api/v1/users/${userId}`,
    //     {headers}).then
    //     ((res) =>{
    //         console.log(res.data);
    //         var temp = res.data[0].activity.status;
    //         console.log(temp);
    //         setDataReport(temp);

    
    //     })
    // }
    const onSubmitForm=async(e) =>{
        e.preventDefault();
        const userData = JSON.stringify({    
        user_username:editProfile.email,
        user_password: editProfile.password,
        email: editProfile.email,
        firstname:editProfile.firstname,   
        lastname:editProfile.lastname,  
        organization: editProfile.organization,
        role: editProfile.role,
        sector: editProfile.sector,
        phone : editProfile.phone_number,
        
        });
        console.log(userData);
        console.log(headers);
        axios.patch(`https://ohs.msabc.online/api/v1/users/${editUserId}`,userData,{
            headers:headers
        })
        .then((res) => {
            console.log(res);
            console.log(res.message);
            // window.location.reload();

        })
        .catch(error =>{
            console.log("Error is -----",error);
        })


    }

    const logOut =() =>{
        localStorage.clear();
        setIsLogin(false);
        navigate('/');

        window.location.reload();
    }

    // useEffect(()=>{
    //     // document.body.classList.add('green-pattern');
    // })
    React.useEffect(()=>{
        fetchUser();
    },[])


    const userList = Object.keys(allUser).map((key,index)=>{
        return <>
                  <tr class="bg-extralightblue border-b">
            <td class="px-2 pl-4 py-1 font-normal text-sm">{(allUser[key].registrationDate)?.substring(0,10)}</td>
            <td class="px-2 py-1 font-normal text-sm">#{allUser[key].id}</td>
            <td class="px-2 py-1 font-normal text-sm"> {allUser[key].email} </td>           
            <td class="px-2 py-1 font-normal text-sm">{(allUser[key].lastActive)?.substring(0,10)}</td>
            <td class="px-2 py-1 font-normal text-sm"><a onClick={()=>{handleProfile(allUser[key])}}>Edit User</a></td> 
            <td class="px-2 py-1 font-normal text-sm"><a onClick={()=>{handleSwitch(allUser[key].id)}}>Switch User </a></td>                
            <td class="px-2 py-1 font-normal text-sm w-5"> <a onClick={()=>{deleteUser(allUser[key].id)}} class="w-4 text-center"><i class="block far fa-trash-alt text-blue"></i><small class="block">Delete</small></a> </td>
            </tr>  
        </>

    })





    return (
        < >
<section class="common-content md:pt-12 md:pb-16 pt-8 pb-8 bg-white overflow-hidden bg-extralightblue">
  <div class="container max-w-7xl ml-auto mr-auto">

 <div class="flex flex-wrap w-full">


  <div class="green-gradient w-full lg:w-1/4 rounded-lg md:px-11 px-5 py-5">
    
    <h3 class="block font-ProximaNovaRg font-bold text-white uppercase mb-1 w-full mt-8">PROFILE</h3>

    <div class="flex items-center mb-10">
      <div class="w-14 rounded-full overflow-hidden mr-3"> <img src="img/user-placeholder.png" alt=""/> </div>
      <div class="block">
        <p class="font-ProximaNovaRg font-bold text-white m-0 leading-tight">Admin</p>        
      </div>
    </div>

    <h3 class="block font-ProximaNovaRg font-bold text-white uppercase mb-1">MENU</h3>
    
    <ul class="block w-full">
      <li class="text-white border-b border-gray-100 block py-1"><a onClick={()=>{setIsHome(true)}}>Users</a></li>
      <li class="text-white border-b border-gray-100  block py-1"><a onClick={handleProfile}>Profile Settings</a></li>     
    </ul>

   

    <p class="text-center w-full mt-12">
      <a onClick={logOut} class="btn-primary bg-[rgba(255,255,255,0.3)] text-white hover:bg-white hover:text-blue font-ProximaNovaRg">Log Out</a>
    </p>

  </div>
  
{isHome? 
 
  <div class="flex flex-wrap w-full lg:w-3/4 lg:pl-6 pt-6 lg:pt-0">


 
   <div class="flex flex-wrap w-full">
   <div class="w-full bg-white rounded-lg overflow-hidden px-5 py-5">

    <div class="overflow-x-auto rounded-md shadow-lg w-full">
      <table class="min-w-full w-full">
        <thead class="border-b bg-white border-gray-300 leading-tight whitespace-nowrap">
          <tr>
            <th scope="col" class="px-2 pl-4 py-1 font-normal font-bold text-darkgray text-left"> Date <i class="fa fa-angle-down"></i> </th>
            <th scope="col" class="px-2 py-1 font-normal font-bold text-darkgray text-left"> User ID <i class="fa fa-angle-down"></i> </th>
            <th scope="col" class="px-2 py-1 font-normal font-bold text-darkgray text-left"> Email <i class="fa fa-angle-down"></i> </th>
            <th scope="col" class="px-2 py-1 font-normal font-bold text-darkgray text-left"> Last Logged in<i class="fa fa-angle-down"></i> </th>
            <th scope="col" class="px-2 py-1 font-normal font-bold text-darkgray text-left"><i class="fa fa-user-edit"></i> </th>
            <th scope="col" class="px-2 py-1 font-normal font-bold text-darkgray text-left"><i class="fa fa-user-cog"></i> </th>
            <th scope="col" class="px-2 py-1">&nbsp;</th>        

          </tr>
        </thead>
        <tbody>         
          {userList}                                                                                        
   
        </tbody>
      </table>


    
    </div>    

    {/* <div class="flex justify-center mt-6 mb-7">
      <nav aria-label="Page navigation">
        <ul class="flex list-style-none">
          <li class="page-item disabled m-1"><a
              class="page-link relative block py-1.5 px-3 border-0 bg-transparent outline-none transition-all duration-300 rounded-full h-10 w-10 text-center border border-gray-400 text-gray-500 pointer-events-none focus:shadow-none"
              href="#" tabindex="-1" aria-disabled="true"><i class="fal fa-angle-left text-xl block leading-6 text-2xl"></i></a></li>
          <li class="page-item m-1"><a
              class="page-link relative block py-1.5 px-3 border-0 bg-transparent outline-none transition-all duration-300 rounded-full h-10 w-10 border border-gray-400 text-center text-gray-800 hover:text-gray-800 hover:bg-gray-200 focus:shadow-none"
              href="#">1</a></li>
          <li class="page-item active m-1"><a
              class="page-link relative h-10 w-10 text-center border border-green-700 block py-1.5 px-3 border-0 bg-green-700 outline-none transition-all duration-300 rounded-full text-white hover:text-white hover:bg-green-700 shadow-md focus:shadow-md"
              href="#">2 <span class="visually-hidden">(current)</span></a></li>
          <li class="page-item m-1"><a
              class="page-link relative h-10 w-10 block py-1.5 border border-gray-400 px-3 border-0 bg-transparent outline-none transition-all duration-300 rounded-full text-gray-800 text-center hover:text-gray-800 hover:bg-gray-200 focus:shadow-none"
              href="#">3</a></li>
          <li class="page-item m-1"><a
              class="page-link relative h-10 w-10 text-center border border-gray-400 block py-1.5 px-3 border-0 bg-transparent outline-none transition-all duration-300 rounded-full text-gray-800 hover:text-gray-800 hover:bg-gray-200 focus:shadow-none"
              href="#"><i class="fal fa-angle-right text-xl block leading-6 text-2xl"></i></a></li>
        </ul>
      </nav>
    </div> */}
    

   </div>
  </div>


  
  
  </div>
  : <>
 <>
            <div class="flex flex-wrap w-full lg:w-3/4 lg:pl-6 pt-6 lg:pt-0">

            <div class="flex flex-wrap w-full">
            <div class="w-full bg-white rounded-lg rounded-t-none overflow-hidden">
      
      
             <div class="px-8 pb-8 lg:px-24 lg:pb-20">
             
      
            <ValidationForm onSubmit={onSubmitForm} >
            <div class="flex flex-wrap w-full">
           <h2 class="text-xl mt-5 font-semibold w-full">Personal Info</h2>
      
           <p></p>
      
      
           <div class="flex flex-wrap clear-both w-full pt-4">
            <div class="w-full md:w-1/2">
            <div class="mt-4 md:mr-6">
              <label class="form-label inline-block mb-2 text-lg">Username</label>
              <TextInput type="text" name="username" id="username"  onChange={handleChange} placeholder="Enter your full name" defaultValue={editProfile.email}
              class="form-control block w-full px-3.5 py-2 text-base font-normal text-black bg-white border border-solid border-gray-300 rounded-xl transition ease-in-out m-0 focus:border-blue focus:outline-none"/>
            </div>
          </div>


          </div>
          <div class="flex flex-wrap clear-both w-full pt-4">
            <div class="w-full md:w-1/2">
            <div class="mt-4 md:mr-6">
              <label class="form-label inline-block mb-2 text-lg">First Name</label>
              <TextInput name="firstname" id="firstname" defaultValue={editProfile.firstname} onChange={handleChange} type="text" placeholder="First Name" 
              class="form-control block w-full px-3.5 py-2 text-base font-normal text-black bg-white border border-solid border-gray-300 rounded-xl transition ease-in-out m-0 focus:border-blue focus:outline-none"/>
            </div>
          </div>
          <div class="w-full md:w-1/2">
            <div class="mt-4 md:ml-6">
              <label class="form-label inline-block mb-2 text-lg">Last Name</label>
              <TextInput 
              name="lastname" id="lastname" value={editProfile.lastname} onChange={handleChange} type="text" placeholder="Last Name"
              class="form-control block w-full px-3.5 py-2 text-base font-normal text-black bg-white border border-solid border-gray-300 rounded-xl transition ease-in-out m-0 focus:border-blue focus:outline-none"/>
            </div>
          </div>
          </div>
          <div class="flex flex-wrap clear-both w-full pt-4">
            <div class="w-full md:w-1/2">
            <div class="mt-4 md:mr-6">
              <label class="form-label inline-block mb-2 text-lg">Organization</label>
              <TextInput name="organization" id="organization" defaultValue={editProfile.organization} onChange={handleChange} type="text" placeholder="Organization" 
              class="form-control block w-full px-3.5 py-2 text-base font-normal text-black bg-white border border-solid border-gray-300 rounded-xl transition ease-in-out m-0 focus:border-blue focus:outline-none"/>
            </div>
          </div>
          <div class="w-full md:w-1/2">
            <div class="mt-4 md:ml-6">
              <label class="form-label inline-block mb-2 text-lg">Role</label>
              <TextInput 
              name="role" id="role" value={editProfile.role} onChange={handleChange} type="text" placeholder="Role"
              class="form-control block w-full px-3.5 py-2 text-base font-normal text-black bg-white border border-solid border-gray-300 rounded-xl transition ease-in-out m-0 focus:border-blue focus:outline-none"/>
            </div>
          </div>
          </div>
          <div class="flex flex-wrap clear-both w-full pt-4">
            <div class="w-full md:w-1/2">
            <div class="mt-4 md:mr-6">
              <label class="form-label inline-block mb-2 text-lg">Sector</label>
              <TextInput name="sector" id="sector" defaultValue={editProfile.sector} onChange={handleChange} type="text" placeholder="Sector" 
              class="form-control block w-full px-3.5 py-2 text-base font-normal text-black bg-white border border-solid border-gray-300 rounded-xl transition ease-in-out m-0 focus:border-blue focus:outline-none"/>
            </div>
          </div>
          <div class="w-full md:w-1/2">
            <div class="mt-4 md:ml-6">
              <label class="form-label inline-block mb-2 text-lg">Phone</label>
              <TextInput 
              name="phone" id="phone" value={editProfile.phone} onChange={handleChange} type="text" placeholder="Phone"
              class="form-control block w-full px-3.5 py-2 text-base font-normal text-black bg-white border border-solid border-gray-300 rounded-xl transition ease-in-out m-0 focus:border-blue focus:outline-none"/>
            </div>
          </div>
          </div>
          
          
      
          <div class="flex flex-wrap clear-both w-full pt-4">
            <div class="w-full md:w-1/2">
            <div class="mt-4 md:mr-6">
              <label class="form-label inline-block mb-2 text-lg">Email</label>
              <TextInput name="email" id="email" defaultValue={editProfile.email} onChange={handleChange} type="email" placeholder="Enter your email" 
              class="form-control block w-full px-3.5 py-2 text-base font-normal text-black bg-white border border-solid border-gray-300 rounded-xl transition ease-in-out m-0 focus:border-blue focus:outline-none"/>
            </div>
          </div>
          <div class="w-full md:w-1/2">
            <div class="mt-4 md:ml-6">
              <label class="form-label inline-block mb-2 text-lg">Password</label>
              <TextInput 
              name="password" id="password" value={editProfile.password} onChange={handleChange} type="password" placeholder="Enter your password"
              class="form-control block w-full px-3.5 py-2 text-base font-normal text-black bg-white border border-solid border-gray-300 rounded-xl transition ease-in-out m-0 focus:border-blue focus:outline-none"/>
            </div>
          </div>
          </div>
      
          <div class="flex space-x-3 justify-end w-full mt-8"> <a onClick={()=>{setIsHome(true)}} class="btn-secondary pl-4 pr-4 border-gray-400 hover:bg-slate-600 hover:border-slate-600">Cancel</a> 
          <input type="submit" value="submit" class="btn-primary green-gradient font-medium hover:bg-black"/> </div>
      
      
      
            </div>
            </ValidationForm>
      
          </div>
      
      
              </div>
              </div>
              </div>

        </>

  </>
}

 </div>

  </div>
</section>


       </>
    );
  };
  const Admin = () => {
    return (
      <>
        <AdminPage />

      </>
    );
  };
  
  export default Admin;