

import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

const ProtectedRoute = () => {
    setTimeout(() => {
        console.log('Hello, World!')
      }, 10000);
    const auth = localStorage.getItem("isAuthenticated");
    console.log("this", auth);
    // If authorized, return an outlet that will render child elements
    // If not, return element that will navigate to login page
    return auth ? <Outlet /> : <Navigate to="/" />;
}
export default ProtectedRoute