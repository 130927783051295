import React, { useEffect, useState } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import SignIn from "../../components/Auth/SignIn";
import Register from "../../components/Auth/Register";
import axios from 'axios';
import parse from "html-react-parser";


const ToolsOv = () => {

    const [toolsOv,setToolsOv] = useState([]);
    const [competencyAssessmentToolOverview,setcompetencyAssessmentToolOverview] = useState([]);
    const [needsAssessmentToolOverview,setneedsAssessmentToolOverview] = useState([]);
    const{postSlug} = useParams();
    const navigate = useNavigate();
    const [isLogin,setIsLogin] = useState(false);
    const zero = 0;


    const fetchData=async()=>{
        axios.get(`https://ohs.msabc.online/api/v1/tools/${postSlug}/areas`).then
        ((res) =>{
            const allTools = res.data[zero].areas; 
            console.log(allTools);
            setToolsOv(allTools);
            console.log(allTools);
            // console.log(res.data);
    
        })
    }

    const fetchcompetencyAssessmentToolOverview=async()=>{
      axios.get(`https://ohs.msabc.online/api/v1/content/page/competencyAssessmentTool`).then
      ((res) =>{
        const overviewData = res.data;
      
          setcompetencyAssessmentToolOverview(overviewData);
        //  console.log(overviewData[0].prose);
      })
  }

  const fetchneedsAssessmentToolOverview=async()=>{
    axios.get(`https://ohs.msabc.online/api/v1/content/page/needsAssessmentTool`).then
    ((res) =>{
      const overviewData = res.data;
        setneedsAssessmentToolOverview(overviewData);
    })
}

    useEffect(()=>{
        // document.body.classList.add('green-pattern');

    })
    React.useEffect(()=>{
      fetchcompetencyAssessmentToolOverview();
      fetchneedsAssessmentToolOverview();
        fetchData();
        checkLogin();

    },[])

    const checkLogin   =() =>{
      const safety_user = localStorage.getItem("safety_alliance_user");
      if(safety_user){
          setIsLogin(true);
      }

  }

    const toolList = toolsOv.map((data,id)=>{
        return <Link to={{pathname:`/tools/${postSlug}/area/${data.areaId}`}} key={id} class="relative p-7 pt-4 rounded-xl shadow-sxl bg-white hover:bg-sky-100 hover:shadow-2xl">
<p class="mb-9"><img src={data.icon} width="61" height="52" alt=""/></p> 
<p class="font-bold text-lightgray tracking-wider">{data.subtitle}</p>        
<h3 style={{color: data.colour}} class={`font-bold text-[${data.colour}] text-xl mb-3`}>{data.title}</h3>
<p class="text-black leading-snug">{parse(data.description)}</p>
   {/* <a href={'/tools/' + postSlug + '/summary'}><span class="font-Lato text-sm mt-5 font-light block">View Summary</span></a> */}
</Link>
    })

    const needsAssessmentTool = needsAssessmentToolOverview.map((data,id)=>{
      if(postSlug==2){ return <div>
        <p class="font-bold text-lightgray tracking-wider mb-4">{data.subtitle}</p>
        <h1 class="text-3xl md:text-4xl lg:text-5xl font-bold mb-4">{data.title}</h1>
        <p class="mb-6">{parse(data.prose)}</p>
        </div>
      }
    })
  
    const competencyAssessmentTool = competencyAssessmentToolOverview.map((data,id)=>{
      if(postSlug==1){
      return <>
        <p class="font-bold text-lightgray tracking-wider mb-4">{data.subtitle}</p>
        <h1 class="text-3xl md:text-4xl lg:text-5xl font-bold mb-4">{data.title}</h1>
        <p class="mb-6">{parse(data.prose)}</p>
        
      </>
      }
    })

    const toolListNotLogin = toolsOv.map((data,id)=>{
      return  <a  data-bs-toggle="modal"
      data-bs-target="#LoginPopup" key={id} class="relative p-7 pt-4 rounded-xl shadow-sxl bg-white hover:shadow-xxl">
<p class="mb-9"><img src={data.icon} width="61" height="52" alt=""/></p> 
<p class="font-bold text-lightgray tracking-wider">{data.subtitle}</p>        
<h3 style={{color: data.colour}} class={`font-bold text-[${data.colour}] text-xl mb-3`}>{data.title}</h3>
<p class="text-black leading-snug">{parse(data.description)}</p>
</a>
  })

    return (
        < >
        
<section class="inner-banner md:pt-14 md:pb-14 pt-8 pb-8 border-b border-gray-100 bg-white">

  <div class="container max-w-7xl ml-auto mr-auto">
  <nav class="rounded-md w-full ml-3">
      <ol class="list-reset flex flex-wrap">
        <li><a href={`/`} class="text-lightgray hover:text-blue">Home</a></li>
        <li><span class="text-lightgray px-2">/</span></li>

      </ol>
    </nav>
     <div class="flex flex-wrap items-center">
      <div class="float-left w-full md:w-1/2">
        <img src={process.env.PUBLIC_URL+"/img/self-assessment-banner.jpg"} width="635" height="423" alt="" class="block ml-auto mr-auto shadow-xxl"/>        
      </div>
      <div class="float-left w-full md:w-1/2 md:pl-12 pt-7 md:pt-0 text-center md:text-left">
       {needsAssessmentTool}
       
       
       
       {competencyAssessmentTool}
       <p>{ (!isLogin)? <a href="#"  data-bs-toggle="modal"
                data-bs-target="#LoginPopup" class="btn-primary">Start Now</a>:
                <>
                <a href={`/tools/${postSlug}/area/${postSlug > 1 ? `9`:`1`}`} class="btn-primary">Start Now</a> 
                <a href={`/tools/${postSlug}/summary/${postSlug > 1 ? `9`:`1`}`}><span class="font-Lato text-sm mt-5 font-light block hover:text-blue">View Summary</span></a>
                </>
              }</p> 
      </div>
    </div>
  </div>
</section>


<section class="common-content md:pt-12 md:pb-16 pt-8 pb-8 bg-white">
  <div class="container max-w-7xl ml-auto mr-auto">
    <div class="flex flex-wrap grid gap-7 lg:gap-9 sm:grid-cols-2 lg:grid-cols-3"> 
  
    { (isLogin)?
    toolList : toolListNotLogin}
     </div>
  </div>
  <SignIn/>
  <Register/>
</section>

       </>
    );
  };
  const ToolsOverview = () => {
    return (
      <>
        <ToolsOv />

      </>
    );
  };
  
  export default ToolsOverview;