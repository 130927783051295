import React, { useEffect, useState,useLayoutEffect } from "react";
import { useParams } from "react-router-dom";
import axios from 'axios';
import $ from "jquery";
import { useNavigate } from "react-router-dom";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5percent from "@amcharts/amcharts5/percent";
import * as am5radar from "@amcharts/amcharts5/radar";

const ReportPage = () => {
     
    const navigate = useNavigate(); 
     const [legendDetail,setLegendDetail] = useState([]);
     const [data,SetData] = useState([]);
     const [loading, setLoading] = useState(false);
     const [performanceDetail,SetPerformanceDetail] = useState([]);
     const [chartData,SetChartData] = useState([]);
     const [lengthData,setLengthData] = useState(0);
    //  const [isReady,setIsReady] = useState(false);

    // const [areaDetail,setAreaDetail] = useState([]);
    // const{postSlug} = useParams();
    // const{areaSlug}= useParams();
    var token = localStorage.getItem("safety_alliance_token");
    var spoofUser = localStorage.getItem("spoof_user");

    if(spoofUser){
      var userId = spoofUser;
    }else{
      var userId = localStorage.getItem("safety_alliance_userId");
    }    
    var{areaSlug}= useParams();
    const{postSlug} = useParams();


    // const [selected, setSelected] = useState("2");


 
    const headers = {
      'accept': '*/*',
      'Authorization': `Bearer ${token}`
      
    }
    const fetchReport=async()=>{
        axios.get(`https://ohs.msabc.online/api/v1/reports/reportCard/tools/${postSlug}/areas/${areaSlug}/users/${userId}?expand=overall`,{headers}).then
        ((res) =>{
          const result = res.data.report.legendTable;
          const temp = res.data.report;
          const tempPerf = res.data.report.performanceTable.rows;
          const chartdata = res.data.report.chart.data;
          // const chartData = res.data.
          console.log(res.data.report);
          console.log(result);
          console.log(chartdata);
          setLegendDetail(result);
          SetPerformanceDetail(tempPerf);
          SetData(temp);
          fetchChart(chartdata);
          setLengthData(chartData.length);

    
        })


    }

    // useEffect(()=>{
    //     // document.body.classList.add('green-pattern');
    // })


 
    useEffect(()=>{
      console.log(chartData);
       fetchReport();
       console.log(chartData);


      
    },[])

    const handleNext=()=>{
      if(areaSlug >= 8){
        navigate("/tools/"+postSlug+"/report/0");

      }else{
      areaSlug = parseInt(areaSlug)+1;
      navigate("/tools/"+postSlug+"/report/"+(parseInt(areaSlug)));
      }

      window.location.reload();
    }

    const handlePrevious=()=>{
      if(areaSlug >0){
      areaSlug = parseInt(areaSlug)-1;
      navigate("/tools/"+postSlug+"/report/"+(parseInt(areaSlug)));

      window.location.reload();
      }
    }

    const shootReport=()=>{
      
    }


    // useEffect(()=>{
    //   if (null === chartData) {
    //     return;
    //  }
    //  console.log(chartData);
    //  fetchChart();
    // },[chartData]);

   const fetchChart=(chartdata) =>{
    
      let root = am5.Root.new("chartdiv");
  
      root.setThemes([
        am5themes_Animated.new(root)
      ]);
  
      var cat = -1;
      var value = 10;
      
      // function generateData() {
      //   value = Math.round(Math.random() * 10);
      //   cat++;
      //   return {
      //     category: "cat" + cat,
      //     value: value
      //   };
      // }
      
      // function generateDatas(count) {
      //   cat = -1;
      //   var data = [];
      //   for (var i = 0; i < count; ++i) {
      //     data.push(generateData());
      //   }
      //   return data;
      // }



      
      
      // Create chart
      // https://www.amcharts.com/docs/v5/charts/radar-chart/
      var chart = root.container.children.push(
        am5radar.RadarChart.new(root, {
          panX: false,
          panY: false,
          wheelX: "panX",
          wheelY: "zoomX",
          innerRadius: am5.p50,
          layout: root.verticalLayout
        })
      );
      
      // Create axes and their renderers
      // https://www.amcharts.com/docs/v5/charts/radar-chart/#Adding_axes
      var xRenderer = am5radar.AxisRendererCircular.new(root, {});
      xRenderer.labels.template.setAll({
        textType:"adjusted"
      });
      
      var xAxis = chart.xAxes.push(
        am5xy.CategoryAxis.new(root, {
          maxDeviation: 0,
          categoryField: "name",
          renderer: xRenderer,
          tooltip: am5.Tooltip.new(root, {})
        })
      );
      
      var yAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          renderer: am5radar.AxisRendererRadial.new(root, {})
        })
      );
      
      // Create series
      // https://www.amcharts.com/docs/v5/charts/radar-chart/#Adding_series
      for (var i = 0; i < 4; i++) {
        var series = chart.series.push(
          am5radar.RadarColumnSeries.new(root, {
            stacked: true,
            name: "Series "+(i+1),
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: "value"+i,
            categoryXField: "name"
          })
        );
      
        series.columns.template.setAll({
          tooltipText: "{name}: {valueY}"
        });
      
        series.data.setAll(
          
            chartdata
        );
        series.appear(1000);
      }
      
      // slider
      var slider = chart.children.push(
        am5.Slider.new(root, {
          orientation: "horizontal",
          start: 0.5,
          width: am5.percent(60),
          centerY: am5.p50,
          centerX: am5.p50,
          x: am5.p50
        })
      );
      slider.events.on("rangechanged", function () {
        var start = slider.get("start");
        var startAngle = 270 - start * 179 - 1;
        var endAngle = 270 + start * 179 + 1;
      
        chart.setAll({ startAngle: startAngle, endAngle: endAngle });
        yAxis.get("renderer").set("axisAngle", startAngle);
      });
      

      xAxis.data.setAll(
        chartdata
        
      );
      
      // Animate chart
      // https://www.amcharts.com/docs/v5/concepts/animations/#Initial_animation
      chart.appear(1000, 100);
    }
  
   // const reportdata = JSON.parse(jsonData);
   
  

//     const toolList = toolsDetail.map((data,id)=>{
//         return <a href={'/tools/' + postSlug + '/area/'+areaSlug+'/Unit/'+ id }><div class="relative p-7 pt-4 pb-16 rounded-3xl shadow-sxl bg-white hover:bg-extralightblue">
// <div class="mb-1 invisible"><img src={process.env.PUBLIC_URL+"/img/pin.png"} alt=""/></div>
// <h3 class="font-bold text-blue text-['17px']"><a>UNIT {data.unitId}</a></h3>
// <p class="font-bold text-black leading-snug">{data.unitName}
// </p>
// <a href={'/tools/' + postSlug + '/area/'+areaSlug+'/Unit/'+ id } class="absolute bottom-0 left-0 px-7 pb-6 right-0 flex justify-between items-center mt-4"><i><img src={process.env.PUBLIC_URL+"/img/pencil.png"} alt=""/></i> <span class="text-blue underline hover:no-underline">Click Here</span></a> </div>
// </a>

//     })

const rangeList = legendDetail.map((data,id)=>{
    return<>
        <th scope="col" class="px-1 py-1 font-normal text-center">{data.range}</th>
    </>
});
const indicatorList = legendDetail.map((data,id)=>{
  return<>
      <td class="px-1 py-1 font-bold text-lg text-center ">{data.indicator}</td>

  </>
});

const proseList = legendDetail.map((data,id)=>{
  return<>
   <td class="px-1 py-1 font-normal text-center">{data.prose}</td>

  </>
});

const performanceList = performanceDetail.map((data,id)=>{
  return <>
      <div class="flex justify-between bg-extralightblue px-2 py-2 rounded-xl shadow-md mt-3 min-w-fit">
        <div class="leading-tight px-2 w-40">
          <p>{data.Competency}</p>
        </div>
        <div class="leading-tight px-2 w-56">
          <p>{data.Description}</p>
        </div>
        <div class="leading-tight px-2 w-44 pt-1 text-center">       
           <div class="bg-blue inline-flex border-2 border-blue rounded-2xl overflow-hidden relative">
            <div class="w-6 h-7 bg-blue border-r-2  border-blue"></div>
            <div class="w-6 h-7 bg-blue border-r-2  border-blue"></div>
            <div class="w-6 h-7 bg-blue border-r-2  border-blue"></div>
            <div class="w-6 h-7 bg-white border-r-2  border-blue"></div>
            <div class="w-6 h-7 bg-white"></div>
            <span class="border-2  absolute top-0.5 left-16 border-blue rounded-full bg-white font-ProximaNovaRg font-bold text-black w-6 h-6 block text-center">{data.Assessment}</span>
           </div>
        </div>
        <div class="leading-tight font-bold text-lg px-2 w-20 text-center pt-2">
          <p>{data.Level}</p>
        </div>    
      </div>  

  </>
})

if (loading) {
  return <p>Data is loading...</p>;
}

    return (
        < >
        
        <section class="inner-banner md:pb-14 pt-8 pb-8 border-b border-gray-100 bg-white no-bg-img">
    
    <div class="container max-w-7xl ml-auto mr-auto">
      <nav class="rounded-md w-full">
        <ol class="list-reset flex flex-wrap">
          <li><a href={`/`} class="text-lightgray hover:text-blue">All Tools</a></li>
          <li><span class="text-lightgray px-2">/</span></li>
          <li><a href={`/tools/${postSlug}`} class="text-lightgray hover:text-blue">Self Assessment Tool</a></li>
          <li><span class="text-lightgray px-2">/</span></li>
          <li class="text-lightgray">Report Card</li>
        </ol>
      </nav>
       <div class="flex flex-wrap items-center mt-8">
        <div class="float-left w-full md:w-1/2">
          <img src={process.env.PUBLIC_URL+"/img/report-card-img-1.jpg"} width="654" height="444" alt="" class="block ml-auto mr-auto shadow-xl rounded-2xl"/>        
        </div>
        <div class="float-left w-full md:w-1/2 md:pl-12 pt-7 md:pt-0 text-center md:text-left">
          <p class="font-bold text-lightgray tracking-wider mb-2">{data.title?.preheader}</p>
          <h1 class="text-3xl md:text-4xl lg:text-5xl font-bold mb-4">{data.title?.header}</h1>
           
          <p class="mb-6">{data.title?.prose}</p>
  
            <div class="overflow-x-auto rounded-md shadow-lg">
              <table class="min-w-full">
                <thead class="border-b bg-extralightblue">
                  <tr>
                  {rangeList}
                  </tr>
                </thead>
                <tbody>
                  <tr class="border-b border-b bg-extralightblue">
                  {indicatorList}
                  </tr>
                  <tr class="bg-extralightblue">
                  {proseList}
                  </tr>            
                </tbody>
              </table>
            </div>
            <a onClick={shootReport} class="btn-primary mt-3 content-center">Get Report</a>

  
         </div>
      </div>
      
    </div>
    
  </section>
  
  <section class="common-content md:pt-12 md:pb-16 pt-8 pb-8 bg-white">
    <div class="container max-w-7xl ml-auto mr-auto">
      <div class="flex flex-wrap">
        <div class="float-left w-full md:w-5/12">
          <div class="summary-data"> <i class="mb-3 block"><img src={process.env.PUBLIC_URL+"/img/assessment-icon-1.png"} width="61" height="52" alt=""/></i>
            <h3 class="text-3xl md:text-4xl font-bold mb-3 md:mb-10 text-blue">{data.chart?.title}</h3>
            

            <div class="py-8 md:pb-14">
            {/* <img src="img/report-card-img-2.jpg" width="" height="" alt=""/> */}
            <div id="chartdiv" style={{ width: "100%", height: "500px" }}></div>

            
            </div>
            <p>{data.chart?.prose}</p>
  
             <div class="flex py-7 px-8 bg-blue text-white justify-between rounded-xl shadow-lg mt-5 leading-tight space-x-2">
  
              <p>{data.overall?.title}</p> <p>{data.overall?.prose}</p>
             </div>
  
          </div>
        </div>
        <div class="float-left w-full md:w-7/12 pt-5 md:pl-9 md:pt-14">        
       <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod temporincididunt ut labore 
        et dolore magna aliqua. Ut enim ad minim veniamquis nostrud exercitation ullamco laboris nisi ut 
        aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit 
        essecillum dolore eu fugiat nulla pariatur.</p>
  
       <div class="overflow-x-auto md:mt-11 mt-4 p-2">
        <div class="flex justify-between px-2 py-2 rounded-xl min-w-fit">
          <div class="leading-tight font-bold text-blue px-2 w-40">
            <p>{data.performanceTable?.headers[0]}</p>
          </div>
          <div class="leading-tight font-bold text-blue px-2 text-center w-56">
            <p>{data.performanceTable?.headers[1]}</p>
          </div>
          <div class="leading-tight font-bold text-blue px-2 text-center w-44">
            <p>{data.performanceTable?.headers[2]}</p>
          </div>
          <div class="leading-tight font-bold text-blue px-2 text-center w-20">
            <p>{data.performanceTable?.headers[3]}</p>
          </div>
         </div> 
  
        <div class="flex justify-between bg-extralightblue px-2 py-2 rounded-xl shadow-md min-w-fit">
        
      </div>
      {performanceList}
  
    
        </div>
      </div>    
      <div class="flex justify-between pr-2 mt-10">
        <a onClick={()=>handlePrevious()} class="flex items-center btn-primary bg-extralightblue text-black hover:text-white shadow-md font-ProximaNovaRg"><i class="fal fa-angle-left text-2xl block leading-none mr-2"></i> Prev</a>
        <a onClick={()=>handleNext()} class="flex items-center btn-primary bg-extralightblue text-black hover:text-white shadow-md font-ProximaNovaRg">Next <i class="fal fa-angle-right text-2xl block leading-none ml-2"></i></a>
  
      </div>
     
    </div>
    </div>
  </section>


       </>
    );
  };
  const Report = () => {
    return (
      <>
      
        <ReportPage />

      </>
    );
  };
  
  export default Report;