import React, { useEffect, useState } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { ValidationForm, TextInput, SelectGroup } from "react-bootstrap4-form-validation";
import axios from 'axios';
import parse from "html-react-parser";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const RegisterComp = () => {

    const [data,setData] = useState({
        email:"",
        password:"",
        cpassword:"",
        phone_number:"",
        firstname:"",
        lastname:"",
        organization:"",
        role:"",
        sector:"",
        username:"",
        email:"",




    });
    const [sectorC, setSectorC] = useState([]);
    const [consent, setConsent] = useState(false);
    const{postSlug} = useParams();
    const navigate = useNavigate();
    const [signInC,setSignInC] = useState({});
    const headers = {
        'Content-Type': 'application/json',
        
      }

    // const fetchData=async()=>{
    //     axios.get(`https://ohs.msabc.online/api/v1/tools/${postSlug}/areas`).then
    //     ((res) =>{
    //         const allTools = res.data; 
    //         setRegisterC(allTools);
    //         console.log(allTools);
    //         // console.log(res.data);
    
    //     })
    // }
    const handleChange = (e) => {
        const value = e.target.value;
        setData({
          ...data,
          [e.target.name]: value
        });
      };

      const handleCheck = ()=>{
        setConsent(!consent);
      }

      const fetchLogin=async()=>{
        axios.get('https://ohs.msabc.online/api/v1/content/page/registration').then
        ((res) =>{
            const allIntro = res.data; 
            const tempSector = res.data.sectors;
            console.log(tempSector);
            setSignInC(allIntro);
            setSectorC(tempSector);

        })
    }


    const onSubmitForm=async(e) =>{
        e.preventDefault();
        if (data.password !== data.cpassword) {
          toast.warn('🦄 Password does not match', {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            });
      }
      else{
        const userData = JSON.stringify({   
        firstname:data.firstname,   
        lastname:data.lastname,  
        user_username:data.email,
        email: data.email,
        password: data.password,
        organization: data.organization,
        role: data.role,
        sector: data.sector,
        phone : data.phone_number,
        consent: consent,

        
        });
        console.log(userData);
        axios.post('https://ohs.msabc.online/auth/register',userData,{
            headers:headers
        })
        .then((res) => {
            console.log(res);
            console.log(res.message);
            window.location.reload();

        })
        .catch(error =>{
            console.log("Error is -----",error);
        })


    }
  }


    // useEffect(()=>{
    //     // document.body.classList.add('green-pattern');
    // })
    React.useEffect(()=>{
        fetchLogin();
    },[])


    const dropDownList = sectorC.map((data,id)=>{
      return<option value={data.content} >{data.content}</option>
  })

    return (
        < >
       <div class="modal fade fixed top-0 left-0  hidden w-full h-full outline-none overflow-x-hidden overflow-y-auto"
        id="SignupPopup" tabindex="-1" aria-labelledby="user popup" aria-hidden="true">
        <div class="modal-dialog modal-xl relative w-auto pointer-events-none">
          <div
            class="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-xl outline-none text-current overflow-hidden">
            <div class="modal-body relative">
              <button type="button"
                class="btn-close absolute right-5 top-5 font-bold box-content w-4 h-4 p-0 border border-black text-black rounded-full opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline leading-4"
                data-bs-dismiss="modal" aria-label="Close">X</button>
              <div class="flex flex-wrap">
                <div class="float-left w-full p-6 lg:w-1/2 lg:p-12 blue-gradient flex items-center justify-center">
                  <div class="p-2">
                    <img src={process.env.PUBLIC_URL+"/img/popup-slide-2.png"} width="470" height="582" alt="" draggable="false"
                      class="ml-auto mr-auto shadow-xxl rounded-tl-[47%] rounded-tr-[47%]"/>
                     {signInC.prose ?<h3 class="font-bold text-lg text-gray-100 text-center mt-6">{parse(signInC.prose)}</h3>
 :<h3 class="font-bold text-lg text-gray-100 text-center mt-6">{signInC.prose}</h3>
}
                     <p class="text-center text-gray-300"></p>
                    <p class="text-black text-xs text-center lg:mt-12 mt-7">© 2022 Safety Alliance. All Rights Reserved
                    </p>
                  </div>
                </div>

                <div class="float-left w-full lg:w-1/2 p-6 lg:p-12 text-left">
                  <ValidationForm onSubmit={onSubmitForm} >
                  <div class="mb-6 lg:mb-7 w-full">
                    <p class="popup-logo"><img src={process.env.PUBLIC_URL+"/img/popup-logos.png"} width="264" height="38" alt=""/></p>
                  </div>
                  <h3 class="text-3xl md:text-4xl font-bold mb-1">{signInC.title}</h3>
                  <p class="mb-4">{signInC.subtitle}</p>
                  <div class="flex flex-wrap">
                    <div class="mb-4 w-full">
                      <label class="form-label inline-block mb-2 font-bold text-lg">First Name</label>

                        <TextInput class="form-control block w-full px-3.5 py-2.5 text-base font-normal text-black bg-extralightblue border border-solid border-extralightblue rounded-xl transition ease-in-out m-0 focus:border-blue focus:outline-none"
                        name="firstname" id="firstname" value={data.firstname} onChange={handleChange} type="text" placeholder="Enter your first name"/>

                    </div>
                  </div>
                  <div class="flex flex-wrap">
                    <div class="mb-4 w-full">
                      <label class="form-label inline-block mb-2 font-bold text-lg">Last Name</label>

                        <TextInput class="form-control block w-full px-3.5 py-2.5 text-base font-normal text-black bg-extralightblue border border-solid border-extralightblue rounded-xl transition ease-in-out m-0 focus:border-blue focus:outline-none"
                        name="lastname" id="lastname" value={data.lastname} onChange={handleChange} type="text" placeholder="Enter your last name"/>

                    </div>
                  </div>

                  <div class="flex flex-wrap">
                    <div class="mb-4 w-full">
                      <label class="form-label inline-block mb-2 font-bold text-lg">Organization</label>
                        <TextInput class="form-control block w-full px-3.5 py-2.5 text-base font-normal text-black bg-extralightblue border border-solid border-extralightblue rounded-xl transition ease-in-out m-0 focus:border-blue focus:outline-none"
                        name="organization" id="organization" value={data.organization} onChange={handleChange} type="text" placeholder="Organization"/>
                    </div>
                  </div>
                  <div class="flex flex-wrap">
                    <div class="mb-4 w-full">
                      <label class="form-label inline-block mb-2 font-bold text-lg">Sector</label>
                        <SelectGroup class="form-control block w-full px-3.5 py-2.5 text-base font-normal text-black bg-extralightblue border border-solid border-extralightblue rounded-xl transition ease-in-out m-0 focus:border-blue focus:outline-none"
                        name="sector" id="sector" value={data.sector} onChange={handleChange} placeholder="Sector">
                        <option value="">--- Please select ---</option>
                        {dropDownList}

                        </SelectGroup>
                    </div>
                  </div>
                  <div class="flex flex-wrap">
                    <div class="mb-4 w-full">
                      <label class="form-label inline-block mb-2 font-bold text-lg">Role</label>
                        <TextInput class="form-control block w-full px-3.5 py-2.5 text-base font-normal text-black bg-extralightblue border border-solid border-extralightblue rounded-xl transition ease-in-out m-0 focus:border-blue focus:outline-none"
                        name="role" id="role" value={data.role} onChange={handleChange} type="text" placeholder="Role"/>
                    </div>
                  </div>
                  <div class="flex flex-wrap">
                    <div class="mb-4 w-full">
                      <label class="form-label inline-block mb-2 font-bold text-lg">Email</label>
                        <TextInput class="form-control block w-full px-3.5 py-2.5 text-base font-normal text-black bg-extralightblue border border-solid border-extralightblue rounded-xl transition ease-in-out m-0 focus:border-blue focus:outline-none"
                        name="email" id="email" value={data.email} onChange={handleChange} type="email" placeholder="Enter your email"/>
                    </div>
                  </div>
                  <div class="flex flex-wrap">
                    <div class="mb-4 w-full">
                      <label class="form-label inline-block mb-2 font-bold text-lg">Phone Number</label>

                        <TextInput class="form-control block w-full px-3.5 py-2.5 text-base font-normal text-black bg-extralightblue border border-solid border-extralightblue rounded-xl transition ease-in-out m-0 focus:border-blue focus:outline-none" name="phone_number" id="phone_number" value={data.phone_number} onChange={handleChange} type="text" placeholder="Enter your number"/>

                    </div>
                  </div>
                  <div class="flex flex-wrap">
                    <div class="mb-4 w-full">
                      <label class="form-label inline-block mb-2 font-bold text-lg">Password</label>

                        <TextInput class="form-control block w-full px-3.5 py-2.5 text-base font-normal text-black bg-extralightblue border border-solid border-extralightblue rounded-xl transition ease-in-out m-0 focus:border-blue focus:outline-none"
                         name="password" id="password" value={data.password} onChange={handleChange} type="password" placeholder="Enter your password"/>

                    </div>
                  </div>
                  <div class="flex flex-wrap">
                    <div class="mb-4 w-full">
                      <label class="form-label inline-block mb-2 font-bold text-lg">Confirm Password</label>
                      <TextInput type="password" name="cpassword" id="cpassword" value={data.cpassword} onChange={handleChange} class="form-control block w-full px-3.5 py-2.5 text-base font-normal text-black bg-extralightblue border border-solid border-extralightblue rounded-xl transition ease-in-out m-0 focus:border-blue focus:outline-none"
                        placeholder="Enter your password"/>
                     </div>
                  </div>
                  <div class="flex flex-wrap">
                    <div class="mb-4 w-full flex flex-wrap justify-between items-center">
                      <div class="form-check mr-3">
                        <input
                          class="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                          type="checkbox" value="" id="consent" onChange={handleCheck}/>
                        <label class="form-check-label inline-block text-gray-800" for="flexCheckDefault">
                          Consent to follow-up for feedback
                        </label>
                      </div>                     
                    </div>
                  </div>
                  <div class="flex flex-wrap">
                    <div class="mb-4 mt-4 w-full text-center">
                      <input type="submit" class="btn-primary font-bold cursor-pointer min-w-[200px]" value="Register"/>
                    </div>
                  </div>
                  <p class="w-full text-center mt-4">Already have an account? <a href="#" data-bs-toggle="modal"
                data-bs-target="#LoginPopup"
                      class="text-lightblue hover:text-blue">Log in</a> </p>
                      </ValidationForm>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer
position="top-center"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="light"
/>
       </>
    );
  };
  const Register = () => {
    return (
      <>
        <RegisterComp />

      </>
    );
  };
  
  export default Register;