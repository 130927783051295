import React, { useEffect, useState } from "react";
import { useParams, Link,useNavigate } from "react-router-dom";
import axios from 'axios';
import parse from 'html-react-parser';


const ToolsDetails = () => {

    const [toolsDetail,setToolsDetail] = useState([]);
    const [areaDetail,setAreaDetail] = useState([]);
    const [areaDetail1,setAreaDetail1] = useState([]);
    const [areaDetail2,setAreaDetail2] = useState([]);
    const{postSlug} = useParams();
    const{areaSlug}= useParams();
    const [isAttempt,SetIsAttempt] = useState([]);
    const [reportUnit,setReportUnit] = useState([]);
    const [isPin,setIsPin] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();


    var userId = localStorage.getItem("safety_alliance_userId");
    var token = localStorage.getItem("safety_alliance_token");
    const arr = [];

    const headers = {
      'accept': '*/*',
      'Authorization': `Bearer ${token}`
      
    }
    const headers2 = {
      'accept': 'application/json',
      'Authorization': `Bearer ${token}`
      
    }

    const fetchResponses = () =>{
      axios.get(`https://ohs.msabc.online/api/v1/responses/users/${userId}/tools/${postSlug}/areas/${areaSlug}`,{headers}).then
      ((res)=>{
        console.log(res.data);
        var temp = res.data;
        // setReportUnit(temp);
        temp.map((data,id)=>{
          if(data.portfolio.length == 0 || data.portfolio == 0){
            arr[id] = false;

          }else{
            arr[id] =true;
          }
        })
        console.log(arr);
        setReportUnit(arr);

      })
    }


    const fetchData=async()=>{
        axios.get(`https://ohs.msabc.online/api/v1/tools/${postSlug}/areas/${areaSlug}`).then
        ((res) =>{
            const allTools = res.data.units; 
            setToolsDetail(allTools);
            const allArea = res.data;
           // alert(res.data.description);

            setAreaDetail(allArea);
            fetchResponses();

             var abc = res.data.description;
           
            var words = abc.split(" ");
            var datacont="";
           
           
            for (var i = 0; i < parseInt(words.length/3) - 1; i++) {
               // words[i] += " ";
                datacont += words[i]+' ';
            }
            

            var words1 = abc.split(" ");
            var datacont1="";
           
            for (var i = parseInt(words1.length/3)-1; i < words1.length; i++) {
              datacont1 += words1[i]+' ';
            }
            setAreaDetail1(datacont);
            setAreaDetail2(datacont1);
            
            // console.log(res.data);
    
        })
    }
    const logOut =() =>{
      localStorage.clear();
      navigate('/');

      window.location.reload();
  }

    const checkAttempt=()=>{
        axios.get(`https://ohs.msabc.online/api/v1/reports/progress/tools/${postSlug}/users/${userId}`,{
          headers:headers
        }
          ).then((res)=>{
            if(res.data.attemptStarted == 0){
              console.log("start attempt");
              axios.post(`https://ohs.msabc.online/api/v1/users/${userId}/tools/${postSlug}`,'',{
                headers:headers2
              }
              ).then((res)=>{
                console.log(res);
              })
          
            }
          }).catch(error =>{
            console.log("Error is -----",error.response.data.message);
            var err = error.response.data.message;
            if(err == "jwt expired"){
              logOut();
            }
        })
      
    }

    // useEffect(()=>{
    //     // document.body.classList.add('green-pattern');
    // })
    React.useEffect(()=>{
        fetchData();
        checkAttempt();
    },[])


    const toolList = toolsDetail.map((data,id)=>{
        return <a href={'/tools/' + postSlug + '/area/'+areaSlug+'/Unit/'+ id } class="relative p-7 pt-4 pb-16 rounded-3xl shadow-sxl bg-white hover:bg-sky-100 ">
<h3 style={{color: areaDetail.colour}} class="font-bold text-blue text-['17px']">UNIT {data.unitId}</h3>
<div class="mb-1 visible">
  {reportUnit[id] ? 
  <img src={process.env.PUBLIC_URL+"/img/pin-small.png"} alt=""/>
  : null
    }
  </div>
<p class="font-bold text-black leading-snug">{data.title}
</p>
<span class="absolute bottom-0 left-0 px-7 pb-6 right-0 flex justify-between items-center mt-4"><i><img src={process.env.PUBLIC_URL+"/img/pencil.png"} alt=""/></i> <span class="text-blue underline hover:no-underline">Click Here</span></span> 
</a>

    })

    if (loading) {
      return <p>Data is loading...</p>;
    }

    return (
        < >

<section class="inner-banner md:pt-9 md:pb-12 pt-8 pb-8 border-b border-gray-100 bg-white">
  <div class="container max-w-7xl ml-auto mr-auto">
    <nav class="rounded-md w-full">
      <ol class="list-reset flex flex-wrap">
        <li><a href="/" class="text-lightgray hover:text-blue">All Tools</a></li>
        <li><span class="text-lightgray px-2">/</span></li>
        { (postSlug == 1) ? 
        <li><a href="/tools/1" class="text-lightgray hover:text-blue">Self Assessment Tool</a></li> :
        <li><a href="/tools/2" class="text-lightgray hover:text-blue">Needs Assessment Tool</a></li>
      }
        <li><span class="text-lightgray px-2">/</span></li>
        <li><a href="" class="text-lightgray hover:text-blue">{areaDetail.title}</a></li>

      </ol>
    </nav>
    <div class="flex flex-wrap mt-10">
      <div class="float-left w-full columns-2">
        <p style={{color: areaDetail.colour}} class="font-bold text-4xl text-blue mb-4">Area {areaDetail.areaId}</p>
        <h1 class="text-3xl md:text-4xl font-bold mb-4">{areaDetail.title}</h1>
        {/* <p>The ability to evaluate hazards and risks is a fundamental skill of the OHS professional. Manyhazards and risks are common across different workplaces and sectors of industry, but even the most unique hazards and risks are assessed and controlled following a common set of principles. Ageneral understanding of hazards in the workplace includes methods of hazard identification andanalysis, and basic knowledge of hazard-specific legislative requirements.</p>
      </div>
      <div class="float-left w-full md:w-7/12 pt-5 md:pt-14 md:pl-9">
        <p>An OHS professional should demonstrate a thorough understanding of the following concepts:</p>
        <ul class="list-disc list-inside mb-3">
          <li>Process and task safety analysis methods</li>
          <li>Complex hazard analysis methods</li>
          <li>Knowledge of exposure standards and their application</li>
          <li>Hazard-specific. legislation and standards</li>
        </ul>
        <p>In the context of job-specific hazards an OHS professional should be aware of:</p>
        <ul class="list-disc list-inside inline">
          <li>Basic underpinning science to understand the hazard's behaviour, how it creates risk, how itcauses damage, and how it can be controlled</li>
          <li>Mechanisms of damage, injury, and health outcomes, including those leading to material unwanted events</li>
          <li> How the hazard occurs in a manufacturing environment</li>
          <li> Risk factors</li>
        </ul> */}
        {/* <article class="prose">{areaDetail.description}</article> */}
        {areaDetail.title? <article class="prose">{parse(areaDetail.description)}</article>: null}

      </div>
      {/* <div class="float-left w-full md:w-7/12 pt-5 md:pl-9 md:pt-10">
      <p>{areaDetail2}</p>
      </div> */}
    </div>
  </div>
</section>

<section class="common-content md:pt-12 md:pb-16 pt-8 pb-8 bg-white">
  <div class="container max-w-7xl ml-auto mr-auto">
    <div class="flex flex-wrap grid gap-7 lg:gap-9 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4"> 
    {toolList}
    {/* <a href="#" class="unit-column">
      <h3>UNIT 101</h3>
      <p>Demonstrate Understanding 
        of Hazards in The Work Place</p>
      <span class="click-here"><i class="fa fa-pencil"></i> <span>Click Here</span></span> </a> 
      <a href="#" class="unit-column">
      <h3>UNIT 102</h3>
      <p>Demonstrate Understanding Of 
        Models of Incident Causation and
        Their Application.</p>
      <span class="click-here"><i class="fa fa-pencil"></i> <span>Click Here</span></span> </a> <a href="#" class="unit-column">
      <h3>UNIT 103</h3>
      <p>Demonstrate Understanding of 
        Control Principles and Their 
        Application.</p>
      <span class="click-here"><i class="fa fa-pencil"></i> <span>Click Here</span></span> </a> <a href="#" class="unit-column">
      <h3>UNIT 104</h3>
      <p>Demonstrate Understanding of 
        Environmental Aspects of 
        Business Processes.</p>
      <span class="click-here"><i class="fa fa-pencil"></i> <span>Click Here</span></span> </a> <a href="#" class="unit-column">
      <h3>UNIT 105</h3>
      <p>Evaluate Administrative 
        Controls.</p>
      <span class="click-here"><i class="fa fa-pencil"></i> <span>Click Here</span></span> </a> <a href="#" class="unit-column">
      <h3>UNIT 106</h3>
      <p>Evaluate Human Factors and 
        Process Controls.</p>
      <span class="click-here"><i class="fa fa-pencil"></i> <span>Click Here</span></span> </a> <a href="#" class="unit-column">
      <h3>UNIT 107</h3>
      <p>Evaluate PPE Controls.</p>
      <span class="click-here"><i class="fa fa-pencil"></i> <span>Click Here</span></span> </a> <a href="#" class="unit-column">
      <h3>UNIT 108</h3>
      <p>Evaluate Engineering and Physical 
        Barrier Controls in The Workplace.</p>
      <span class="click-here"><i class="fa fa-pencil"></i> <span>Click Here</span></span> </a> <a href="#" class="unit-column">
      <h3>UNIT 109</h3>
      <p>Evaluate Emergency Response
        Procedures.</p>
      <span class="click-here"><i class="fa fa-pencil"></i> <span>Click Here</span></span> </a> <a href="#" class="unit-column">
      <h3>UNIT 110</h3>
      <p>Evaluate New Emergency
        Risks.</p>
      <span class="click-here"><i class="fa fa-pencil"></i> <span>Click Here</span></span> </a>  */}
      </div>
  </div>
</section>
       </>
    );
  };
  const ToolsDetail = () => {
    return (
      <>
        <ToolsDetails />

      </>
    );
  };
  
  export default ToolsDetail;