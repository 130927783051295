import logo from './logo.svg';
import 'tw-elements';
import './App.css';
import {Route, Routes} from "react-router-dom";
import Home from "./pages/Home/Home"
import Header from "./components/Header/Header"
import ToolsOverview from './pages/ToolsOverview/ToolsOverview';
import ToolsDetail from './pages/ToolsDetail/ToolsDetail';
import Summary from './pages/Summary/Summary';
import Report from './pages/Report/Report';
import UnitDetail from './pages/Unit/UnitDetail';
import ProtectedRoute from './components/ProtectedRoute';
import ProtectedAdminRoute from './components/ProtectedAdminRoute';
import Profile from './pages/Profile/Profile';
import Admin from './pages/Admin/Admin';
const App= ()=> {

  // const [isAdmin,setIsAdmin] = useState(false);

  // if(localStorage.getItem("is_Admin") == 1){

  // }



  return (
    <>
    
    <Header/>
    <Routes>
      <Route path="/" element={<Home/>}/>
      <Route path="/profile" element={<ProtectedRoute/>} >
        <Route path="/profile" element={<Profile/>} />


      </Route>

      <Route path="/tools/:postSlug" element={<ToolsOverview/>}/>
      <Route path="/tools/:postSlug" element={<ProtectedRoute/>} >
        <Route path="/tools/:postSlug/summary/:areaSlug" element={<Summary/>} />
        <Route path="/tools/:postSlug/report/:areaSlug" element={<Report/>} />
        <Route path="/tools/:postSlug/area/:areaSlug" element={<ToolsDetail/>} />
        <Route path="/tools/:postSlug/area/:areaSlug/Unit/:unitSlug" element={<UnitDetail/>}/>
      </Route>

      <Route path="/admin" element={<ProtectedAdminRoute/>} >
        <Route path="/admin" element={<Admin/>} />

      </Route>

    </Routes>
    </>
  );
}

export default App;
