

import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

const ProtectedAdminRoute = () => {
    const temp = localStorage.getItem("is_Admin");
    console.log("this", temp);
    // If authorized, return an outlet that will render child elements
    // If not, return element that will navigate to login page
    return temp ? <Outlet /> : <Navigate to="/"  /> ;
}
export default ProtectedAdminRoute