import React,{useState, useEffect}  from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from 'axios';
import { Modal } from "react-bootstrap";
import Register from "../Auth/Register";
import SignIn from "../Auth/SignIn";
import $ from "jquery";
// import logo from "../../../public/img/manufacturing-logo.png";


const Header =()=>{
    const [showRegister,setShowRegister] = useState(false);
    const [showLogin,setShowLogin] = useState(false);
    const [isLogin,setIsLogin] = useState(false);
    const [isAdmin,setIsAdmin] = useState(false);
    const [lorem,setLorem] = useState({});
    const [isDropdown,setIsDropdown] = useState(false);

    const navigate = useNavigate()

 
    useEffect(()=>{
        document.body.classList.add('green-pattern');


    })
    React.useEffect(()=>{
        

        checkLogin();
        fetchLorem();

    },[])


        const fetchLorem=()=>{
            axios.get('https://ohs.msabc.online/api/v1/content/page/logo').then
            ((res) =>{
                var temp = res.data;
                setLorem(temp);
      
            })
        }

    const checkLogin   =() =>{
        const safety_user = localStorage.getItem("safety_alliance_user");
        const admin = localStorage.getItem("is_Admin");

        if(admin == 1){
            setIsAdmin(true);
        }

        if(safety_user){
            setIsLogin(true);
        }

    }
    const logOut =() =>{
        localStorage.clear();
        setIsLogin(false);
        navigate('/');

        window.location.reload();
    }
    const toggleRegister = () => {
        setShowRegister(current => !current);
    }
    const toggleLogin = () => {
        setShowLogin(current => !current);
    }



    return(
        <>
        <header class="bg-white shadow-sm z-50">

        <div class="container max-w-7xl ml-auto mr-auto">
        <div class="flex justify-between items-center py-6">
              <div class="float-left"> 
                <div class="flex items-center"><Link to={{pathname:`/`}}  class="md:mr-8 mr-3"><img src={lorem[1]?.link} alt="Manufacturing Safety Alliance" width="204" height="35"/></Link><Link to={{pathname:`/`}} class="md:mr-8 mr-3"><img src={lorem[0]?.link} alt="British Columbia" width="169" height="59"/></Link></div>
              </div>




                { (isLogin) ? 
                

<div class="float-right">
<div class="relative inline-flex align-middle w-full ml-4">
  <div onClick={()=>setIsDropdown(!isDropdown)} class="profile-click flex items-center cursor-pointer">
    
    <div class="w-9 sm:w-14 rounded-full overflow-hidden"> <img src={process.env.PUBLIC_URL+"/img/user-placeholder.png"} alt=""/> </div>
    <i class="fa fa-caret-down text-lightgray ml-2"></i>
   
  </div>

  { isDropdown ? 
  <div class="absolute top-8 sm:top-16 bg-white  text-base right-0 float-left py-2 list-none text-left rounded shadow-lg mt-1 w-36" id="dropdown-profile">
    { isAdmin ? 
    <>
     <a href="/admin" class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap text-black hover:text-blue"> Admin </a>
     <a href="/profile" class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap text-black hover:text-blue"> Profile </a>  
     </>
     :     <a href="/profile" class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap text-black hover:text-blue"> Profile </a> 

    }
  <a href="#pablo" class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap text-black hover:text-blue" onClick={logOut}> Logout </a> 
  </div>
  : null
}
</div>

</div>

                :
                // <div class="float-right">
                // <div class="flex space-x-2 justify-center"> 
                //   <a onClick={toggleLogin} class="btn-secondary"> <i class="fa fa-user"></i> <span>Login</span></a> 
                //   <a onClick={toggleRegister}  class="btn-primary"> <i class="fa fa-user-plus"></i> <span>Sign Up</span> </a> 
                // </div>

                <div class="float-right">
            <div class="flex space-x-2 justify-center"> 
            <a href="#" class="btn-secondary" data-bs-toggle="modal"
                data-bs-target="#LoginPopup">
                     <i class="fa fa-user"></i> <span>Login</span></a> 
            <a href="#"
                class="btn-primary" data-bs-toggle="modal" data-bs-target="#SignupPopup">  <i class="fa fa-user-plus"></i> <span>Sign Up</span> </a> </div>
          </div>

  


                }
                </div>
            </div>
        </header>
        {/* { (showLogin ? 
     
       <SignIn/> 
       : null )}
       { (showRegister ? 
       <Register/> 
       : null )} */}
  

        


        </>
    );
};

export default Header;